import React from 'react';
import { FormikValues } from 'formik';

import { PrequalificationInputLabel } from 'enums/PrequalificationInputLabel';
import { PrequalificationInputName } from 'enums/PrequalificationInputName';
import { lenderPaidCompensationOptions } from 'components/PrequalificationForm/selectInputOptions';
import InputSelect from 'components/InputSelect';

import styles from './LenderInformation.module.scss';

interface LenderInformationProps {
  onChange: (inputName: PrequalificationInputName, value: string) => void;
  formikValues: FormikValues;
  getErrorMessage: (inputName: PrequalificationInputName) => string | undefined;
}

const LenderInformation = ({ onChange, formikValues, getErrorMessage }: LenderInformationProps) => {
  return (
    <div>
      <h3 className={styles.sectionTitle}>Lender Information</h3>
      <InputSelect
        label={PrequalificationInputLabel.LenderPaidCompensation}
        onChange={(value) => onChange(PrequalificationInputName.LenderPaidCompensation, value)}
        value={formikValues[PrequalificationInputName.LenderPaidCompensation]}
        options={lenderPaidCompensationOptions}
        name={PrequalificationInputName.LenderPaidCompensation}
        errorMessage={getErrorMessage(PrequalificationInputName.LenderPaidCompensation)}
        placeholder="Select"
        className={styles.formInput}
      />
    </div>
  );
};

export default LenderInformation;
