import React, { ChangeEventHandler, FC } from 'react';
import { FilerRangeInputName } from 'enums/FilerRangeInputName';
import RangeSlider from 'components/RangeSlider';
import RangeValue from 'components/Filters/ProductDetailsFilter/RangeValue';

import styles from './RangeFilter.module.scss';

const MIN_VALUE_INDEX = 0;
const MAX_VALUE_INDEX = 1;

interface RangeFilterProps {
  title: string;
  rangeValue: number[];
  onRangeSliderChange: (event: Event, value: number | number[], activeThumb: number) => void;
  onChange: ChangeEventHandler<HTMLInputElement>;
  minValue: number;
  maxValue: number;
  step: number;
  minValueErrorMessage?: string;
  maxValueErrorMessage?: string;
  minInputName: FilerRangeInputName;
  maxInputName: FilerRangeInputName;
  rangeSliderName: string;
}

const RangeFilter: FC<RangeFilterProps> = ({
  title,
  rangeValue,
  onRangeSliderChange,
  minValue,
  maxValue,
  step,
  minValueErrorMessage,
  maxValueErrorMessage,
  minInputName,
  maxInputName,
  onChange,
  rangeSliderName,
}) => (
  <div className={styles.container}>
    <p className={styles.rangeTitle}>{title}</p>
    <div className={styles.rangeSliderContainer}>
      <RangeSlider
        rangeValue={rangeValue}
        onChange={onRangeSliderChange}
        minValue={minValue}
        maxValue={maxValue}
        step={step}
        name={rangeSliderName}
      />
    </div>
    <div className={styles.rangeValuesContainer}>
      <RangeValue
        validValue={rangeValue[MIN_VALUE_INDEX]}
        errorMessage={minValueErrorMessage}
        onChange={onChange}
        name={minInputName}
        allowNegative
        allowZero
      />
      <div className={styles.rangeSeparator} />
      <RangeValue
        validValue={rangeValue[MAX_VALUE_INDEX]}
        errorMessage={maxValueErrorMessage}
        onChange={onChange}
        name={maxInputName}
        allowNegative
        allowZero
      />
    </div>
  </div>
);

export default RangeFilter;
