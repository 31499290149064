import React, { FC, useEffect } from 'react';
import clsx from 'clsx';

import styles from './Popup.module.scss';

interface PopupProps {
  className?: string;
  disableBodyScroll?: boolean;
}

const Popup: FC<PopupProps> = ({ children, className, disableBodyScroll }) => {
  useEffect(() => {
    if (disableBodyScroll) {
      document.body.style.overflowY = 'hidden';
    }
  }, []);

  useEffect(() => {
    return () => {
      if (disableBodyScroll) {
        document.body.style.overflowY = 'scroll';
      }
    };
  }, []);

  return <div className={clsx(styles.container, className)}>{children}</div>;
};

export default Popup;
