import React from 'react';
import { useHistory } from 'react-router';
import InnerPageLayout from 'components/InnerPageLayout';

import styles from './NotFound.module.scss';

export const NotFound = () => {
  const history = useHistory();

  const handleGoToHomePage = () => {
    history.push('/');
  };

  return (
    <InnerPageLayout className={styles.innerPageLayout}>
      <div className={styles.container}>
        <p className={styles.errorNumber}>404</p>
        <h3 className={styles.errorTitle}>Oops. The page you are looking for doesn’t exist.</h3>
        <p className={styles.errorSubtitle}>You may have mistyped the address or the page may have moved.</p>
        <p onClick={handleGoToHomePage} className={styles.homeLink}>
          Take me back to the home page.
        </p>
      </div>
    </InnerPageLayout>
  );
};

export default NotFound;
