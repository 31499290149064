import React, { FC } from 'react';
import { FormikValues } from 'formik';
import { PrequalificationInputLabel } from 'enums/PrequalificationInputLabel';
import { PrequalificationInputName } from 'enums/PrequalificationInputName';
import { creditEventsOptions, mortgagePaymentHistoryOptions } from 'components/PrequalificationForm/selectInputOptions';

import NumberInput from 'components/NumberInput';
import InputSelect from 'components/InputSelect';

import styles from './CreditProfile.module.scss';

interface CreditProfileProps {
  onChange: (inputName: PrequalificationInputName, value: string) => void;
  getErrorMessage: (inputName: PrequalificationInputName) => string | undefined;
  formikValues: FormikValues;
}

const CreditProfile: FC<CreditProfileProps> = ({ onChange, getErrorMessage, formikValues }) => (
  <div className={styles.container}>
    <h3 className={styles.sectionTitle}>Credit Profile</h3>
    <NumberInput
      label={PrequalificationInputLabel.CreditScore}
      onChange={(event) => onChange(PrequalificationInputName.CreditScore, event.target.value)}
      value={formikValues[PrequalificationInputName.CreditScore]}
      name={PrequalificationInputName.CreditScore}
      allowNegative={false}
      thousandSeparator
      errorMessage={getErrorMessage(PrequalificationInputName.CreditScore)}
      className={styles.formInput}
      maxLength={3}
    />
    <InputSelect
      label={PrequalificationInputLabel.MortgagePaymentHistory}
      onChange={(value) => onChange(PrequalificationInputName.MortgagePaymentHistory, value)}
      value={formikValues[PrequalificationInputName.MortgagePaymentHistory]}
      options={mortgagePaymentHistoryOptions}
      name={PrequalificationInputName.MortgagePaymentHistory}
      errorMessage={getErrorMessage(PrequalificationInputName.MortgagePaymentHistory)}
      placeholder="Select"
      className={styles.formInput}
    />
    <InputSelect
      label={PrequalificationInputLabel.CreditEvents}
      onChange={(value) => onChange(PrequalificationInputName.CreditEvents, value)}
      value={formikValues[PrequalificationInputName.CreditEvents]}
      options={creditEventsOptions}
      name={PrequalificationInputName.CreditEvents}
      errorMessage={getErrorMessage(PrequalificationInputName.CreditEvents)}
      placeholder="Select"
      className={styles.formInput}
    />
  </div>
);

export default CreditProfile;
