import React, { FC } from 'react';

import styles from './DropdownVariable.module.scss';

interface DropdownVariableProps {
  title: string;
  value: string;
  className?: string;
}

const DropdownVariable: FC<DropdownVariableProps> = ({ title, value, className }) => (
  <div className={className}>
    <p className={styles.title}>{title}</p>
    <p className={styles.value}>{value}</p>
  </div>
);

export default DropdownVariable;
