export enum StrategyId {
  AssetQualification = '642a6c80e5ffdd4b730f9254',
  BankStatement = '642a6c80e5ffdd4b730f7a6b',
  FullDocumentation = '642a6c7fe5ffdd4b730f6260',
  ForeignNational = '642a6c81e5ffdd4b730fb510',
  DSCR = '642a6c82e5ffdd4b730fe86d',
  DscrNoRatio = '642a6c7de5ffdd4b730f4223',
  DSCRLowRatio = '642a6c82e5ffdd4b730fd4e5',
  DscrElite = '642a6c82e5ffdd4b730ffbcf',
  MixedUse = '642a6c82e5ffdd4b73100e29',
  ProfitLossOnly = '6492de0576154763b4672ece',
}
