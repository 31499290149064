import React, { FC } from 'react';
import clsx from 'clsx';

import styles from './DeclinedProductItem.module.scss';

interface DeclinedProductItemProps {
  loanProduct: string;
  declineReasons: string[];
}

const DeclinedProductItem: FC<DeclinedProductItemProps> = ({ loanProduct, declineReasons }) => {
  const variablesMapping = [
    { name: 'Loan Product', value: loanProduct },
    {
      name: 'Reason',
      value: declineReasons.map((reason) => <span key={reason}>{`${reason}. `}</span>),
    },
  ];
  return (
    <div className={styles.container}>
      {variablesMapping.map((variable) => (
        <div key={variable.name} className={styles.variableContainer}>
          <p className={styles.variableName}>{variable.name}</p>
          <p className={clsx(styles.variableValue, variable.name === 'Reason' && styles.declineReasonValue)}>
            {variable.value}
          </p>
        </div>
      ))}
    </div>
  );
};

export default DeclinedProductItem;
