import React, { FC } from 'react';
import clsx from 'clsx';

import { env } from 'env';

import useLogoPathByPortalName from 'hooks/useLogoPathByPortalName';

import { ReactSVG } from 'react-svg';

import styles from './Header.module.scss';

interface HeaderProps {
  disposedInBlock?: boolean;
}

const Header: FC<HeaderProps> = ({ disposedInBlock }) => {
  const { path } = useLogoPathByPortalName(env.REACT_APP_PORTAL_NAME);

  return (
    <div className={clsx(disposedInBlock ? styles.headerInBlock : styles.headerContainer)}>
      <div className={styles.headerLogo}>
        <ReactSVG src={path} />
      </div>
    </div>
  );
};

export default Header;
