import React, { FC } from 'react';
import clsx from 'clsx';
import { PriceAdjustment } from 'api/PreQualificationApi';
import { coerceNumberToDisplayValue } from 'utils/coerceNumberToDisplayValue';
import { ProductTerm } from 'enums/ProductTerm';
import { PriceAdjustmentName } from 'enums/PriceAdjustmentName';

import { isNotNullOrUndefined } from 'utils/isNotNullOrUndefined';

import styles from './LlpaData.module.scss';

export interface LlpaDataProps {
  priceAdjustmentsList: PriceAdjustment[];
  lenderPaidCompensation?: number;
  basePrice: number;
  netPrice: number;
  cap: number;
  currentProductTerm: ProductTerm;
  points?: number;
}

const LlpaData: FC<LlpaDataProps> = ({
  priceAdjustmentsList,
  lenderPaidCompensation,
  basePrice,
  netPrice,
  cap,
  currentProductTerm,
  points,
}) => {
  const renderPriceAdjustments = () => {
    return priceAdjustmentsList.map((priceAdjustment) => {
      if (!priceAdjustment.name || !priceAdjustment.value) {
        return null;
      }

      if (
        (currentProductTerm === ProductTerm.Fixed30YearIntOnly10 ||
          currentProductTerm === ProductTerm.ARM56IntOnly10ThirtyYearTerm) &&
        priceAdjustment.name === PriceAdjustmentName.FortyYearTermInterestOnly
      ) {
        return null;
      }

      if (
        (currentProductTerm === ProductTerm.Fixed40YearIntOnly10 ||
          currentProductTerm === ProductTerm.ARM56IntOnly10FortyYearTerm) &&
        priceAdjustment.name === PriceAdjustmentName.ThirtyYearTermInterestOnly
      ) {
        return null;
      }

      if (
        priceAdjustment.name === PriceAdjustmentName.FortyYearMaturity &&
        (currentProductTerm === ProductTerm.Fixed30YearIntOnly10 ||
          currentProductTerm === ProductTerm.ARM56IntOnly10ThirtyYearTerm ||
          currentProductTerm === ProductTerm.ARM56 ||
          currentProductTerm === ProductTerm.Fixed30Year)
      ) {
        return null;
      }

      return (
        <div className={clsx(styles.variableContainer)} key={priceAdjustment.name}>
          <p className={styles.defaultVariableName}>{priceAdjustment.name}</p>
          <p
            className={clsx(
              styles.priceAdjustmentValue,
              priceAdjustment.value < 0 && styles.priceAdjustmentValueNegative,
            )}
          >
            {coerceNumberToDisplayValue(priceAdjustment.value)}
          </p>
        </div>
      );
    });
  };

  const showPriceCap = cap < 0;
  const isLenderPaidZero = lenderPaidCompensation === 0;

  return (
    <div className={styles.container}>
      {basePrice && (
        <div className={clsx(styles.basePriceContainer, styles.variableContainer)}>
          <p className={styles.basePrice}>Base Price</p>
          <p className={styles.basePrice}>{coerceNumberToDisplayValue(basePrice)}</p>
        </div>
      )}
      <div className={styles.defaultVariablesContainer}>
        {(lenderPaidCompensation || isLenderPaidZero) && (
          <div className={clsx(styles.variableContainer, styles.brokerPaidCompValueContainer)}>
            <p className={styles.defaultVariableName}>Lender Paid Compensation</p>
            <p className={clsx(styles.brokerPaidCompValue, isLenderPaidZero && styles.brokerPaidZeroValue)}>
              {coerceNumberToDisplayValue(lenderPaidCompensation)}
            </p>
          </div>
        )}
        <div className={styles.sectionTitle}>LLPA's</div>
        {renderPriceAdjustments()}
        {showPriceCap && (
          <div className={clsx(styles.variableContainer)}>
            <p className={styles.defaultVariableName}>Price Cap</p>
            <p className={clsx(styles.priceAdjustmentValue, styles.priceAdjustmentValueNegative)}>
              {coerceNumberToDisplayValue(cap)}
            </p>
          </div>
        )}
      </div>
      {netPrice && (
        <div className={clsx(styles.variableContainer, styles.netPriceContainer)}>
          <p className={styles.netPrice}>Net Price</p>
          <p className={styles.netPrice}>{coerceNumberToDisplayValue(netPrice)}</p>
        </div>
      )}
      {isNotNullOrUndefined(points) && (
        <div className={clsx(styles.variableContainer, styles.pointsContainer)}>
          <p className={styles.netPrice}>Points</p>
          <p className={clsx(styles.netPrice, styles.priceAdjustmentValueNegative)}>
            {coerceNumberToDisplayValue(points!)}
          </p>
        </div>
      )}
    </div>
  );
};

export default LlpaData;
