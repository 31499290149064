import React, { FC } from 'react';
import { FormikValues } from 'formik';
import { NumberFormatValues } from 'react-number-format';
import InputSelect from 'components/InputSelect';
import NumberInput from 'components/NumberInput';
import { PrequalificationInputLabel } from 'enums/PrequalificationInputLabel';
import { PrequalificationInputName } from 'enums/PrequalificationInputName';
import { InputPrefixAndSuffixType } from 'enums/InputPrefixAndSuffixType';
import { Tooltip } from 'enums/Tooltip';
import { ProductType } from 'enums/ProductType';
import {
  citizenshipOptions,
  escrowWaiverOptions,
  incomeDocumentTypeOptions,
  interestOnlyOptions,
  loanPurposeOptions,
  prepaymentPenaltyOptions,
  reservesOptions,
  selfEmployedOptions,
} from 'components/PrequalificationForm/selectInputOptions';

import styles from './LoanCriteria.module.scss';

const MONETARY_VALUE_MAX_LENGTH = 12;
const MAX_PERCENT_VALUE = 100;
const MAX_DSCR_VALUE = 10;

interface LoanCriteriaProps {
  onChange: (inputName: PrequalificationInputName, value: string) => void;
  validatePercentInput: (inputObj: NumberFormatValues, maxValue: number) => boolean;
  showDebtToIncome: boolean;
  showDscr: boolean;
  showIncomeDocumentType: boolean;
  productType: ProductType;
  formikValues: FormikValues;
  getErrorMessage: (inputName: PrequalificationInputName) => string | undefined;
}

const LoanCriteria: FC<LoanCriteriaProps> = ({
  onChange,
  validatePercentInput,
  showDebtToIncome,
  showDscr,
  showIncomeDocumentType,
  productType,
  formikValues,
  getErrorMessage,
}) => {
  return (
    <div className={styles.container}>
      <h3 className={styles.sectionTitle}>Loan Criteria</h3>
      <div className={styles.loanCriteriaContainer}>
        <div className={styles.inputs}>
          <InputSelect
            label={PrequalificationInputLabel.LoanPurpose}
            onChange={(value) => onChange(PrequalificationInputName.LoanPurpose, value)}
            value={formikValues[PrequalificationInputName.LoanPurpose]}
            options={loanPurposeOptions}
            name={PrequalificationInputName.LoanPurpose}
            placeholder="Select"
            className={styles.formInput}
            errorMessage={getErrorMessage(PrequalificationInputName.LoanPurpose)}
          />
          <NumberInput
            label={PrequalificationInputLabel.PurchasePriceOrEstimatedValue}
            onChange={(event) => onChange(PrequalificationInputName.PurchasePriceOrEstimatedValue, event.target.value)}
            value={formikValues[PrequalificationInputName.PurchasePriceOrEstimatedValue]}
            name={PrequalificationInputName.PurchasePriceOrEstimatedValue}
            maxLength={MONETARY_VALUE_MAX_LENGTH}
            prefix={InputPrefixAndSuffixType.Monetary}
            allowNegative={false}
            thousandSeparator
            errorMessage={getErrorMessage(PrequalificationInputName.PurchasePriceOrEstimatedValue)}
            className={styles.formInput}
          />
          <NumberInput
            label={PrequalificationInputLabel.LoanToValue}
            onChange={(event) => onChange(PrequalificationInputName.LoanToValue, event.target.value)}
            value={formikValues[PrequalificationInputName.LoanToValue]}
            name={PrequalificationInputName.LoanToValue}
            suffix={InputPrefixAndSuffixType.Percentage}
            allowNegative={false}
            thousandSeparator
            errorMessage={getErrorMessage(PrequalificationInputName.LoanToValue)}
            isAllowed={(values) => validatePercentInput(values, MAX_PERCENT_VALUE)}
            className={styles.formInput}
          />
          <NumberInput
            label={PrequalificationInputLabel.LoanAmount}
            value={formikValues[PrequalificationInputName.LoanAmount]}
            name={PrequalificationInputName.LoanAmount}
            prefix={InputPrefixAndSuffixType.Monetary}
            thousandSeparator
            disabled
            className={styles.formInput}
          />
          <div className={styles.formInput}>
            <InputSelect
              label={PrequalificationInputLabel.Reserves}
              onChange={(value) => onChange(PrequalificationInputName.Reserves, value)}
              value={formikValues[PrequalificationInputName.Reserves]}
              options={reservesOptions}
              name={PrequalificationInputName.Reserves}
              placeholder="Select"
              errorMessage={getErrorMessage(PrequalificationInputName.Reserves)}
            />
            {productType === ProductType.AlternativeDocumentation && (
              <p className={styles.reservesNotification}>
                Asset Qualification loans require 110% of the loan amount plus additional months reserves. Your
                selection indicates the borrower has X months in addition to the required 110% of the loan amount
              </p>
            )}
          </div>
          <div className={styles.formInput}>
            <InputSelect
              label={PrequalificationInputLabel.SelfEmployed}
              onChange={(value) => onChange(PrequalificationInputName.SelfEmployed, value)}
              value={formikValues[PrequalificationInputName.SelfEmployed]}
              options={selfEmployedOptions}
              name={PrequalificationInputName.SelfEmployed}
              placeholder="Select"
              errorMessage={getErrorMessage(PrequalificationInputName.SelfEmployed)}
            />
          </div>
        </div>
        <div className={styles.inputs}>
          {showDebtToIncome && (
            <NumberInput
              label={PrequalificationInputLabel.DebtToIncome}
              onChange={(event) => onChange(PrequalificationInputName.DebtToIncome, event.target.value)}
              value={formikValues[PrequalificationInputName.DebtToIncome]}
              name={PrequalificationInputName.DebtToIncome}
              allowNegative={false}
              thousandSeparator
              errorMessage={getErrorMessage(PrequalificationInputName.DebtToIncome)}
              isAllowed={(values) => validatePercentInput(values, MAX_PERCENT_VALUE)}
              className={styles.formInput}
              decimalPlacesAmount={2}
            />
          )}
          {showDscr && (
            <NumberInput
              label={PrequalificationInputLabel.DebtServiceCoverageRatio}
              onChange={(event) => onChange(PrequalificationInputName.DebtServiceCoverageRatio, event.target.value)}
              value={formikValues[PrequalificationInputName.DebtServiceCoverageRatio]}
              name={PrequalificationInputName.DebtServiceCoverageRatio}
              allowNegative={false}
              thousandSeparator
              errorMessage={getErrorMessage(PrequalificationInputName.DebtServiceCoverageRatio)}
              isAllowed={(values) => validatePercentInput(values, MAX_DSCR_VALUE)}
              className={styles.formInput}
              decimalPlacesAmount={2}
              tooltip={Tooltip.Dscr}
              subLabel="Optional"
            />
          )}
          {showIncomeDocumentType && (
            <InputSelect
              label={PrequalificationInputLabel.IncomeDocumentType}
              onChange={(value) => onChange(PrequalificationInputName.IncomeDocumentType, value)}
              value={formikValues[PrequalificationInputName.IncomeDocumentType]}
              options={incomeDocumentTypeOptions}
              name={PrequalificationInputName.IncomeDocumentType}
              placeholder="Select"
              className={styles.formInput}
              errorMessage={getErrorMessage(PrequalificationInputName.IncomeDocumentType)}
            />
          )}
          <InputSelect
            label={PrequalificationInputLabel.InterestOnly}
            onChange={(value) => onChange(PrequalificationInputName.InterestOnly, value)}
            value={formikValues[PrequalificationInputName.InterestOnly]}
            options={interestOnlyOptions}
            name={PrequalificationInputName.InterestOnly}
            placeholder="Select"
            className={styles.formInput}
            errorMessage={getErrorMessage(PrequalificationInputName.InterestOnly)}
          />
          <InputSelect
            label={PrequalificationInputLabel.Citizenship}
            onChange={(value) => onChange(PrequalificationInputName.Citizenship, value)}
            value={formikValues[PrequalificationInputName.Citizenship]}
            options={citizenshipOptions}
            name={PrequalificationInputName.Citizenship}
            placeholder="Select"
            className={styles.formInput}
            errorMessage={getErrorMessage(PrequalificationInputName.Citizenship)}
          />
          <InputSelect
            label={PrequalificationInputLabel.PrepaymentPenalty}
            onChange={(value) => onChange(PrequalificationInputName.PrepaymentPenalty, value)}
            value={formikValues[PrequalificationInputName.PrepaymentPenalty]}
            options={prepaymentPenaltyOptions}
            name={PrequalificationInputName.PrepaymentPenalty}
            placeholder="Select"
            className={styles.formInput}
            errorMessage={getErrorMessage(PrequalificationInputName.PrepaymentPenalty)}
          />
          <InputSelect
            label={PrequalificationInputLabel.EscrowWaiver}
            onChange={(value) => onChange(PrequalificationInputName.EscrowWaiver, value)}
            value={formikValues[PrequalificationInputName.EscrowWaiver]}
            options={escrowWaiverOptions}
            name={PrequalificationInputName.EscrowWaiver}
            placeholder="Select"
            className={styles.formInput}
            errorMessage={getErrorMessage(PrequalificationInputName.EscrowWaiver)}
          />
        </div>
      </div>
    </div>
  );
};
export default LoanCriteria;
