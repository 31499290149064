import { StrategyId } from 'enums/StrategyId';
import { StrategyName } from 'enums/StrategyName';

export interface Strategy {
  name: StrategyName;
  value: StrategyId;
}

export const retailStrategies: Strategy[] = [
  { name: StrategyName.AssetQualification, value: StrategyId.AssetQualification },
  { name: StrategyName.ForeignNational, value: StrategyId.ForeignNational },
  { name: StrategyName.BankStatement, value: StrategyId.BankStatement },
  { name: StrategyName.FullDocumentation, value: StrategyId.FullDocumentation },
  { name: StrategyName.DSCR, value: StrategyId.DSCR },
  { name: StrategyName.DSCRLowRatio, value: StrategyId.DSCRLowRatio },
  { name: StrategyName.DscrElite, value: StrategyId.DscrElite },
  { name: StrategyName.DscrNoRatio, value: StrategyId.DscrNoRatio },
  { name: StrategyName.ProfitLossOnly, value: StrategyId.ProfitLossOnly },
];

export const wholesailStrategies: Strategy[] = [
  { name: StrategyName.AssetQualification, value: StrategyId.AssetQualification },
  { name: StrategyName.FullDocumentation, value: StrategyId.FullDocumentation },
  { name: StrategyName.BankStatement, value: StrategyId.BankStatement },
  { name: StrategyName.DSCRLowRatio, value: StrategyId.DSCRLowRatio },
  { name: StrategyName.DSCR, value: StrategyId.DSCR },
  { name: StrategyName.MixedUse, value: StrategyId.MixedUse },
  { name: StrategyName.DscrElite, value: StrategyId.DscrElite },
  { name: StrategyName.DscrNoRatio, value: StrategyId.DscrNoRatio },
  { name: StrategyName.ProfitLossOnly, value: StrategyId.ProfitLossOnly },
  { name: StrategyName.ForeignNational, value: StrategyId.ForeignNational },
];
