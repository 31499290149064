import React from 'react';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from 'handlers';
import InnerPageLayout from 'components/InnerPageLayout';
import PrequalificationResultDetails from 'components/PrequalificationResultDetails';

const ResultDetails = () => {
  const { passedPrograms } = useSelector((state: RootState) => state.prequalificationResults);
  const { productType } = useSelector((state: RootState) => state.preQualificationForm);
  const { currentProductId } = useSelector((state: RootState) => state.prequalificationResultDetails);

  if (!passedPrograms) {
    return <Redirect to="/" />;
  }

  const currentProgram = passedPrograms!.find((program) => program.id === currentProductId);

  return (
    <InnerPageLayout>
      <PrequalificationResultDetails currentProgram={currentProgram!} productType={productType} />
    </InnerPageLayout>
  );
};

export default ResultDetails;
