import { LoanPurpose } from 'enums/LoanPurpose';
import { IncomeDocumentType } from 'enums/IncomeDocumentType';
import { InterestOnlyType } from 'enums/InterestOnlyType';
import { CitizenshipType } from 'enums/CitizenshipType';
import { PrepaymentPenaltyType } from 'enums/PrepaymentPenaltyType';
import { PropertyType } from 'enums/PropertyType';
import { MortgagePaymentHistoryType } from 'enums/MortgagePaymentHistoryType';
import { CreditEventsType } from 'enums/CreditEventsType';
import { OccupancyType } from 'enums/OccupancyType';
import { EscrowWaiverType } from 'enums/EscrowWaiverType';
import { ReservesType } from 'enums/ReservesType';
import { SelfEmployedType } from 'enums/SelfEmployedType';
import { VestingType } from 'enums/VestingType';

export const loanPurposeOptions = [
  LoanPurpose.Purchase,
  LoanPurpose.RefinanceCashOut,
  LoanPurpose.RefinanceRateAndTerm,
];

export const incomeDocumentTypeOptions = [
  IncomeDocumentType.OneYearFullDoc,
  IncomeDocumentType.TwoYearFullDoc,
  IncomeDocumentType.TwelveMonthsBankStatements,
  IncomeDocumentType.TwentyFourMonthsBankStatements,
  IncomeDocumentType.OneYear1099,
  IncomeDocumentType.TwoYear1099,
  IncomeDocumentType.OneYearPL,
  IncomeDocumentType.TwoYearPL,
  IncomeDocumentType.AssetQualification,
];

export const interestOnlyOptions = [InterestOnlyType.Yes, InterestOnlyType.No];

export const citizenshipOptions = [
  CitizenshipType.UsCitizen,
  CitizenshipType.PermanentResidentAlien,
  CitizenshipType.NonPermanentResidentAlien,
  CitizenshipType.ForeignNational,
];

export const prepaymentPenaltyOptions = [
  PrepaymentPenaltyType.NoPrepayment,
  PrepaymentPenaltyType.OneYearPrepayment,
  PrepaymentPenaltyType.TwoYearPrepayment,
  PrepaymentPenaltyType.ThreeYearPrepayment,
  PrepaymentPenaltyType.FiveYearPrepayment,
];

export const propertyTypeOptions = [
  PropertyType.SfrOrPud,
  PropertyType.WarrantableCondo,
  PropertyType.NonWarrantableCondo,
  PropertyType.TwoUnit,
  PropertyType.ThreeUnit,
  PropertyType.FourUnit,
  PropertyType.FiveToEightUnit,
  PropertyType.MixedUse,
];

export const mortgagePaymentHistoryOptions = [
  MortgagePaymentHistoryType.NoLatesLast24Months,
  MortgagePaymentHistoryType.OneMultipleThirtyToSixMonths,
  MortgagePaymentHistoryType.OneMultipleThirtyToTwelveMonths,
  MortgagePaymentHistoryType.TwoMultipleThirtyTwelveMonths,
  MortgagePaymentHistoryType.OneMultipleThirtyTwentyFourMonths,
];

export const creditEventsOptions = [
  CreditEventsType.NoPriorBkFcSsDil,
  CreditEventsType.BkFcSsDilLessThan2Yrs,
  CreditEventsType.BkFcSsDilTwoToFourYrs,
];

export const occupancyTypeOptions = [
  OccupancyType.PrimaryResidence,
  OccupancyType.SecondHome,
  OccupancyType.InvestmentProperty,
];

export const vestingTypeOptions = [VestingType.Individual, VestingType.Trust, VestingType.LLCCorporation];

export const escrowWaiverOptions = [EscrowWaiverType.Yes, EscrowWaiverType.No];

export const reservesOptions = [
  ReservesType.ThreeMonths,
  ReservesType.SixMonths,
  ReservesType.NineMonths,
  ReservesType.TwelveMonths,
  ReservesType.EightTeenMonths,
  ReservesType.TwentyFourMonths,
];

export const selfEmployedOptions = [SelfEmployedType.Yes, SelfEmployedType.No];

export const lenderPaidCompensationOptions = [
  '0.000',
  '1.000',
  '1.125',
  '1.250',
  '1.375',
  '1.500',
  '1.625',
  '1.750',
  '1.875',
  '2.000',
  '2.125',
  '2.250',
  '2.375',
  '2.500',
  '2.625',
  '2.750',
];
