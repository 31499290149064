import { createSlice, PayloadAction } from '@reduxjs/toolkit';


const initialState = false;

const isStylesLoadedReducer = createSlice({
  name: 'isStylesLoaded',
  initialState,
  reducers: {
    setIsStylesLoaded: (state: boolean, { payload }: PayloadAction<boolean>) => {
      state = payload;

      return state;
    },
  },
});

export const { setIsStylesLoaded } = isStylesLoadedReducer.actions;

export default isStylesLoadedReducer.reducer;
