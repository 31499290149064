import React, { FC } from 'react';
import clsx from 'clsx';
import Separator from 'components/Separator';
import { coerceNumberToDisplayValue } from 'utils/coerceNumberToDisplayValue';
import { PassedProductTableHeaderTitle } from 'enums/PassedProductTableHeaderTitle';
import { ReactComponent as TextFileIcon } from 'images/text-file-icon.svg';

import { isNotNullOrUndefined } from 'utils/isNotNullOrUndefined';

import styles from './PassedProductItem.module.scss';

interface ProductItemProps {
  loanProduct: string;
  productTerms: string;
  lockTerm: string;
  onClick: () => void;
  bpcRate?: number;
  bpcPrice?: number;
  lpcRate?: number;
  lpcPrice?: number;
  bpcPoint?: number;
  aprPoint?: number;
  className?: string;
}

interface Variable {
  name: string;
  value: string;
}

const PassedProductItem: FC<ProductItemProps> = ({
  loanProduct,
  productTerms,
  lpcRate,
  lpcPrice,
  bpcRate,
  bpcPrice,
  bpcPoint,
  aprPoint,
  lockTerm,
  className,
  onClick,
}) => {
  const getVariableValue = (variable: Variable) => {
    if (variable.name === PassedProductTableHeaderTitle.RatePoints) {
      const splitVariableValue = variable.value.split('/');
      return (
        <>
          <span>{splitVariableValue[0]}</span>
          <span>/</span>
          <span className={clsx(+splitVariableValue[1] < 0 && styles.negativePoint)}>{splitVariableValue[1]}</span>
        </>
      );
    }

    if (variable.name === PassedProductTableHeaderTitle.APR) {
      return `${variable.value}%`;
    }

    return variable.value;
  };

  const variablesMapping = [
    { name: PassedProductTableHeaderTitle.LoanProduct, value: loanProduct },
    {
      name: PassedProductTableHeaderTitle.ProductTerms,
      value: productTerms,
    },
    lpcRate &&
      lpcPrice && {
        name: PassedProductTableHeaderTitle.LPC,
        value: `${coerceNumberToDisplayValue(lpcRate)}/${coerceNumberToDisplayValue(lpcPrice)}`,
      },
    {
      name: lpcRate && lpcPrice ? PassedProductTableHeaderTitle.BPC : PassedProductTableHeaderTitle.RatePoints,
      value:
        bpcRate && bpcPrice
          ? `${coerceNumberToDisplayValue(bpcRate)}/${coerceNumberToDisplayValue(
              isNotNullOrUndefined(bpcPoint) ? bpcPoint! : bpcPrice,
            )}`
          : '-',
    },
    Boolean(aprPoint) && {
      name: PassedProductTableHeaderTitle.APR,
      value: `${coerceNumberToDisplayValue(aprPoint!)}`,
    },
    { name: PassedProductTableHeaderTitle.LockTerm, value: `${lockTerm} Day` },
  ];

  return (
    <div className={clsx(lpcRate ? styles.container : styles.retailPortalContainer, className)} onClick={onClick}>
      {variablesMapping.map(
        (variable) =>
          variable && (
            <div key={variable.name} className={styles.variableContainer}>
              <p className={styles.variableName}>{variable.name}</p>
              <p
                className={clsx(
                  variable.name === PassedProductTableHeaderTitle.LoanProduct
                    ? styles.loanProductTitle
                    : styles.variableValue,
                )}
              >
                {getVariableValue(variable)}
              </p>
            </div>
          ),
      )}
      <Separator className={styles.separator} />
      <div className={styles.variableButton}>
        <TextFileIcon className={styles.testFieldIcon} />
        <p className={styles.llpAndPricing}>{lpcRate ? 'LLPA’s / Pricing' : 'Rates / Points'}</p>
      </div>
    </div>
  );
};

export default PassedProductItem;
