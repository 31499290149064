import React, { FC } from 'react';
import clsx from 'clsx';
import Header from 'components/Header';
import Footer from 'components/Footer';

import styles from './InnerPageLayout.module.scss';

interface InnerPageLayoutProps {
  className?: string;
}

const InnerPageLayout: FC<InnerPageLayoutProps> = ({ children, className }) => (
  <div className={styles.container}>
    <Header />
    <div className={clsx(styles.content, className)}>{children}</div>
    <Footer isInsidePage />
  </div>
);

export default InnerPageLayout;
