import React from 'react';
import clsx from 'clsx';

import styles from 'components/CheckableSelect/CheckableSelect.module.scss';
import { ReactComponent as CheckMark } from 'components/CheckableSelect/checkmark.svg';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
}

const Checkbox = ({ label, name, onChange, className, ...rest }: CheckboxProps) => (
  <div className={className}>
    <label className={styles.container}>
      <span className={styles.checkboxLabel}>{label}</span>
      <input type="checkbox" name={name} {...rest} onChange={onChange} />
      <span className={clsx(styles.checkmark, styles.checkboxCheckmark)} />
      <CheckMark className={styles.checkmarkIcon} />
    </label>
  </div>
);

export default Checkbox;
