import React, { FC } from 'react';
import clsx from 'clsx';
import { ReactComponent as BackArrow } from 'images/back-arrow.svg';

import styles from './BackLink.module.scss';

interface BackLinkProps {
  title: string;
  onClick: () => void;
  className?: string;
}

const BackLink: FC<BackLinkProps> = ({ title, onClick, className }) => (
  <div className={clsx(styles.container, className)} onClick={onClick}>
    <BackArrow className={styles.backArrowLink} /> <p className={styles.title}>{title}</p>
  </div>
);

export default BackLink;
