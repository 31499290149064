import React, { FC, useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { settings } from 'settings';

import { PortalContext, PortalParamsType } from 'index';

import styles from './Footer.module.scss';

const updateTime = () => {
  return DateTime.now().setZone('America/New_York').toFormat('MM/dd/yyyy h:mm:ss a');
};

interface FooterProps {
  isInsidePage?: boolean;
}

const Footer: FC<FooterProps> = ({ isInsidePage }) => {
  const [time, setTime] = useState<string>(updateTime());

  const { portalType } = useContext(PortalContext) as PortalParamsType;

  useEffect(() => {
    const interval = setInterval(() => setTime(updateTime()), 1000);

    return () => clearInterval(interval);
  });

  return (
    <div className={clsx(styles.footer, isInsidePage && styles.insidePageFooter)}>
      <div className={styles.container}>
        <div className={styles.copyright}>
          {settings[portalType].footerDisclaimer}
          <p className={clsx(styles.copyright, styles.clock)}>{time} EST</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
