import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from 'pages/Home';
import NotFound from 'pages/NotFound';
import Results from 'pages/Results';
import ResultDetails from 'pages/ResultDetails';

export default () => {
  return (
    <Route path="/">
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/results" exact component={Results} />
        <Route path="/details" exact component={ResultDetails} />
        <Route component={NotFound} />
      </Switch>
    </Route>
  );
};
