import React, { FC, useContext } from 'react';
import { Formik } from 'formik';
import { RootState } from 'handlers';
import { ProductType } from 'enums/ProductType';

import { PortalContext, PortalParamsType } from 'index';

import { useSelector } from 'react-redux';

import { validationSchema } from './validationSchema';

import PrequalificationInputs from './PrequalificationInputs';

import styles from './PrequalificationForm.module.scss';

interface PrequalificationFormProps {
  productType: ProductType;
}

const PrequalificationForm: FC<PrequalificationFormProps> = ({ productType }) => {
  const { portalType } = useContext(PortalContext) as PortalParamsType;

  const defaultValues = useSelector((state: RootState) => state.preQualificationForm.application);

  return (
    <div className={styles.container}>
      <Formik
        initialValues={defaultValues}
        onSubmit={() => {}}
        validateOnMount
        enableReinitialize
        validationSchema={validationSchema(productType, portalType)}
      >
        {({ ...formikProps }) => (
          <PrequalificationInputs productType={productType} portalType={portalType!} {...formikProps} />
        )}
      </Formik>
    </div>
  );
};

export default PrequalificationForm;
