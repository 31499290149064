import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FilerRangeInputName } from 'enums/FilerRangeInputName';
import {
  ProductDetailsFilterState,
  setPricesAndRatesFilter,
  setPricesAndRatesFilterOpen,
} from 'handlers/prequalificationResultDetails';
import { PassedProgramsData } from 'api/PreQualificationApi';
import { compareArrays } from 'utils/compareTwoArrays';
import PopupHeader from 'components/Popup/PopupHeader';
import Separator from 'components/Separator';
import FilterButtons from 'components/Filters/FilterButtons';

import RangeFilter from './RangeFilter';

import styles from './ProductDetailsFilter.module.scss';

const RANGE_STEP = 0.05;
const MIN_VALUE_INDEX = 0;
const MAX_VALUE_INDEX = 1;

interface ProductDetailsFilterProps {
  onPopupClose: () => void;
  filter: ProductDetailsFilterState;
  currentProgram: PassedProgramsData;
  filterAvailableValues: ProductDetailsFilterState;
}

const ProductDetailsFilter: FC<ProductDetailsFilterProps> = ({
  onPopupClose,
  currentProgram,
  filter,
  filterAvailableValues,
}) => {
  const dispatch = useDispatch();

  const lpcRates = currentProgram.lpcData.map((item) => item.rate);
  const lpcPrices = currentProgram.lpcData.map((item) => item.price);
  const bpcRates = currentProgram.bpcData.map((item) => item.rate);
  const bpcPrices = currentProgram.bpcData.map((item) => item.price);
  const bpcPoints = currentProgram.bpcData.map((item) => item.point);

  const [lpcRateRange, setLpcRateRange] = useState(filter.lpcRateRange);
  const [lpcPriceRange, setLpcPriceRange] = useState(filter.lpcPriceRange);

  const [bpcRateRange, setBpcRateRange] = useState(filter.bpcRateRange);
  const [bpcPriceRange, setBpcPriceRange] = useState(filter.bpcPriceRange);
  const [bpcPointsRange, setBpcPointsRange] = useState(filter.bpcPointsRange);

  const errorsInitialState = {
    [FilerRangeInputName.LpcRateMinValue]: '',
    [FilerRangeInputName.LpcRateMaxValue]: '',
    [FilerRangeInputName.LpcPriceMinValue]: '',
    [FilerRangeInputName.LpcPriceMaxValue]: '',

    [FilerRangeInputName.BpcRateMinValue]: '',
    [FilerRangeInputName.BpcRateMaxValue]: '',
    [FilerRangeInputName.BpcPriceMinValue]: '',
    [FilerRangeInputName.BpcPriceMaxValue]: '',

    [FilerRangeInputName.BpcPointsMinValue]: '',
    [FilerRangeInputName.BpcPointsMaxValue]: '',
  };

  const [errors, setErrors] = useState(errorsInitialState);

  const validateValue = (value: string | number, valuesSet: number[], inputName: FilerRangeInputName) => {
    if (Number(value) < Math.min(...valuesSet) || Number(value) > Math.max(...valuesSet)) {
      setErrors({ ...errors, [inputName]: 'Invalid value' });
      return;
    }

    setErrors({ ...errors, [inputName]: '' });
  };

  const onInputChange = (
    value: string,
    valuesSet: number[],
    inputName: FilerRangeInputName,
    range: number[],
    setRange: (range: number[]) => void,
  ) => {
    if (inputName.includes('Min')) {
      setRange([Number(value), range[MAX_VALUE_INDEX]]);
    }

    if (inputName.includes('Max')) {
      setRange([range[MIN_VALUE_INDEX], Number(value)]);
    }

    validateValue(value, valuesSet, inputName);
  };

  const onRangeSliderChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: number[],
    setRange: (range: number[]) => void,
  ) => {
    setRange(value);

    setErrors({
      ...errors,
      [`${event.target.name}MinValue`]: '',
      [`${event.target.name}MaxValue`]: '',
    });
  };

  const isFilterInInitialState =
    compareArrays(filter.lpcRateRange, lpcRateRange) &&
    compareArrays(filter.lpcPriceRange, lpcPriceRange) &&
    compareArrays(filter.bpcRateRange, bpcRateRange) &&
    compareArrays(filter.bpcPriceRange, bpcPriceRange) &&
    compareArrays(filter.bpcPointsRange, bpcPointsRange);

  const filterIsNotValid = Object.values(errors).some((item) => item !== '');

  const isApplyButtonDisabled = filterIsNotValid || isFilterInInitialState;

  const isResetDisabled =
    compareArrays(filter.lpcRateRange, filterAvailableValues.lpcRateRange) &&
    compareArrays(filter.lpcPriceRange, filterAvailableValues.lpcPriceRange) &&
    compareArrays(filter.bpcRateRange, filterAvailableValues.bpcRateRange) &&
    compareArrays(filter.bpcPriceRange, filterAvailableValues.bpcPriceRange) &&
    compareArrays(filter.bpcPointsRange, filterAvailableValues.bpcPointsRange);

  const handleApply = () => {
    dispatch(
      setPricesAndRatesFilter({
        lpcRateRange,
        lpcPriceRange,
        bpcRateRange,
        bpcPriceRange,
        bpcPointsRange,
      }),
    );

    dispatch(setPricesAndRatesFilterOpen(false));
  };

  const handleReset = () => {
    dispatch(setPricesAndRatesFilter(filterAvailableValues));

    dispatch(setPricesAndRatesFilterOpen(false));
  };

  return (
    <div className={styles.container}>
      <PopupHeader onPopupClose={onPopupClose} title={lpcRateRange ? 'Filters' : 'Filter'} />
      {lpcRateRange && (
        <div className={styles.variablesSection}>
          <h4 className={styles.sectionTitle}>LPC</h4>
          <div className={styles.sectionFiltersContainer}>
            <RangeFilter
              title="Rate Range"
              minValue={Math.min(...lpcRates)}
              maxValue={Math.max(...lpcRates)}
              step={RANGE_STEP}
              rangeValue={lpcRateRange}
              onRangeSliderChange={(event, value) =>
                onRangeSliderChange(
                  event as unknown as React.ChangeEvent<HTMLInputElement>,
                  value as number[],
                  setLpcRateRange,
                )
              }
              maxValueErrorMessage={errors[FilerRangeInputName.LpcRateMaxValue]}
              minValueErrorMessage={errors[FilerRangeInputName.LpcRateMinValue]}
              onChange={(event) =>
                onInputChange(
                  event.target.value,
                  lpcRates,
                  event.target.name as FilerRangeInputName,
                  lpcRateRange,
                  setLpcRateRange,
                )
              }
              minInputName={FilerRangeInputName.LpcRateMinValue}
              maxInputName={FilerRangeInputName.LpcRateMaxValue}
              rangeSliderName="LpcRate"
            />
            <RangeFilter
              title="Price Range"
              minValue={Math.min(...lpcPrices)}
              maxValue={Math.max(...lpcPrices)}
              step={RANGE_STEP}
              rangeValue={lpcPriceRange!}
              onRangeSliderChange={(event, value) =>
                onRangeSliderChange(
                  event as unknown as React.ChangeEvent<HTMLInputElement>,
                  value as number[],
                  setLpcPriceRange,
                )
              }
              maxValueErrorMessage={errors[FilerRangeInputName.LpcPriceMaxValue]}
              minValueErrorMessage={errors[FilerRangeInputName.LpcPriceMinValue]}
              onChange={(event) =>
                onInputChange(
                  event.target.value,
                  lpcPrices,
                  event.target.name as FilerRangeInputName,
                  lpcPriceRange!,
                  setLpcPriceRange,
                )
              }
              minInputName={FilerRangeInputName.LpcPriceMinValue}
              maxInputName={FilerRangeInputName.LpcPriceMaxValue}
              rangeSliderName="LpcPrice"
            />
          </div>
        </div>
      )}
      <div className={styles.variablesSection}>
        {lpcRateRange && <h4 className={styles.sectionTitle}>BPC</h4>}
        <div className={styles.sectionFiltersContainer}>
          <RangeFilter
            title="Rate Range"
            minValue={Math.min(...bpcRates)}
            maxValue={Math.max(...bpcRates)}
            step={RANGE_STEP}
            rangeValue={bpcRateRange}
            onRangeSliderChange={(event, value) =>
              onRangeSliderChange(
                event as unknown as React.ChangeEvent<HTMLInputElement>,
                value as number[],
                setBpcRateRange,
              )
            }
            maxValueErrorMessage={errors[FilerRangeInputName.BpcRateMaxValue]}
            minValueErrorMessage={errors[FilerRangeInputName.BpcRateMinValue]}
            onChange={(event) =>
              onInputChange(
                event.target.value,
                bpcRates,
                event.target.name as FilerRangeInputName,
                bpcRateRange,
                setBpcRateRange,
              )
            }
            minInputName={FilerRangeInputName.BpcRateMinValue}
            maxInputName={FilerRangeInputName.BpcRateMaxValue}
            rangeSliderName="BpcRate"
          />
          {lpcRateRange ? (
            <RangeFilter
              title="Price Range"
              minValue={Math.min(...bpcPrices)}
              maxValue={Math.max(...bpcPrices)}
              step={RANGE_STEP}
              rangeValue={bpcPriceRange}
              onRangeSliderChange={(event, value) =>
                onRangeSliderChange(
                  event as unknown as React.ChangeEvent<HTMLInputElement>,
                  value as number[],
                  setBpcPriceRange,
                )
              }
              maxValueErrorMessage={errors[FilerRangeInputName.BpcPriceMaxValue]}
              minValueErrorMessage={errors[FilerRangeInputName.BpcPriceMinValue]}
              onChange={(event) =>
                onInputChange(
                  event.target.value,
                  bpcPrices,
                  event.target.name as FilerRangeInputName,
                  bpcPriceRange,
                  setBpcPriceRange,
                )
              }
              minInputName={FilerRangeInputName.BpcPriceMinValue}
              maxInputName={FilerRangeInputName.BpcPriceMaxValue}
              rangeSliderName="BpcPrice"
            />
          ) : (
            <RangeFilter
              title="Points Range"
              minValue={Math.min(...bpcPoints)}
              maxValue={Math.max(...bpcPoints)}
              step={RANGE_STEP}
              rangeValue={bpcPointsRange!}
              onRangeSliderChange={(event, value) =>
                onRangeSliderChange(
                  event as unknown as React.ChangeEvent<HTMLInputElement>,
                  value as number[],
                  setBpcPointsRange,
                )
              }
              maxValueErrorMessage={errors[FilerRangeInputName.BpcPointsMaxValue]}
              minValueErrorMessage={errors[FilerRangeInputName.BpcPointsMinValue]}
              onChange={(event) =>
                onInputChange(
                  event.target.value,
                  bpcPoints,
                  event.target.name as FilerRangeInputName,
                  bpcPointsRange!,
                  setBpcPointsRange,
                )
              }
              minInputName={FilerRangeInputName.BpcPointsMinValue}
              maxInputName={FilerRangeInputName.BpcPointsMaxValue}
              rangeSliderName="BpcPoints"
            />
          )}
        </div>
      </div>
      <Separator className={styles.separator} />
      <FilterButtons
        resetButtonDisabled={isResetDisabled}
        applyButtonDisabled={isApplyButtonDisabled}
        handleResetFilters={handleReset}
        handleApply={handleApply}
      />
    </div>
  );
};

export default ProductDetailsFilter;
