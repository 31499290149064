import React, { ChangeEventHandler, FC } from 'react';
import NumberInput from 'components/NumberInput';
import { FilerRangeInputName } from 'enums/FilerRangeInputName';

const RANGE_VALUE_MAX_LENGTH = 7;
const RANGE_VALUE_DECIMAL_PLACES = 3;
const RANGE_INPUT_EMPTY_VALUE = 0;

interface RangeValueProps {
  onChange: ChangeEventHandler<HTMLInputElement>;
  name: FilerRangeInputName;
  validValue: number;
  allowNegative?: boolean;
  allowZero?: boolean;
  errorMessage?: string;
}

const RangeValue: FC<RangeValueProps> = ({
  errorMessage,
  onChange,
  validValue,
  name,
  allowNegative = false,
  allowZero = false,
}) => {
  return (
    <NumberInput
      value={validValue === RANGE_INPUT_EMPTY_VALUE && !allowZero ? '' : `${validValue}`}
      allowNegative={allowNegative}
      decimalPlacesAmount={RANGE_VALUE_DECIMAL_PLACES}
      errorMessage={errorMessage}
      onChange={onChange}
      name={name}
      maxLength={RANGE_VALUE_MAX_LENGTH}
    />
  );
};

export default RangeValue;
