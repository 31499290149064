import { PassedProductTableHeaderTitle } from 'enums/PassedProductTableHeaderTitle';
import { ProductVariable } from 'enums/ProductVariable';
import { DeclinedProductTableHeaderTitle } from 'enums/DeclinedProductTableHeaderTitle';
import { Apr, PricesToRates } from 'api/PreQualificationApi';

type SortItemType = {
  [key in ProductVariable]?: string | string[] | PricesToRates | Apr;
};

const getVariableName = (field: PassedProductTableHeaderTitle | DeclinedProductTableHeaderTitle) => {
  switch (field) {
    case PassedProductTableHeaderTitle.LoanProduct:
      return ProductVariable.LoanProduct;

    case PassedProductTableHeaderTitle.ProductTerms:
      return ProductVariable.ProductTerms;

    case PassedProductTableHeaderTitle.LockTerm:
      return ProductVariable.LockTerm;

    case DeclinedProductTableHeaderTitle.DeclineReason:
      return ProductVariable.DeclineReasons;

    default:
      return null as never;
  }
};

export const createDefaultSortingHandler =
  <SortItem extends SortItemType>(
    field: PassedProductTableHeaderTitle | DeclinedProductTableHeaderTitle,
    ascending: boolean,
  ) =>
  (firstItem: SortItem, secondItem: SortItem) => {
    if (firstItem[getVariableName(field)]! < secondItem[getVariableName(field)]!) {
      return ascending ? -1 : 1;
    }

    if (firstItem[getVariableName(field)]! > secondItem[getVariableName(field)]!) {
      return ascending ? 1 : -1;
    }

    return 0;
  };
