import { useEffect, useState } from 'react';

const DEFAULT_PORTAL_NAME = 'standard';

const useLogoByPortalName = (portalName: string = DEFAULT_PORTAL_NAME) => {
  const [logoPath, setLogoPath] = useState('');

  const importIcon = async () => {
    const logo = await import(`images/portalsLogo/${portalName}.svg`);
    setLogoPath(logo.default);
  };

  useEffect(() => {
    importIcon();
  }, [portalName]);

  return {
    path: logoPath,
  };
};

export default useLogoByPortalName;
