type EnvType = {
  REACT_APP_API_URL: string;
  REACT_APP_WHITE_LABEL: string;
  REACT_APP_PORTAL_NAME: string;
};

declare global {
  interface Window {
    env: EnvType;
  }
}

export const env: EnvType = { ...process.env, ...window.env };
