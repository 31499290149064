import { coerceToIntegerNumber } from './coerceToIntegerNumber';
import { coerceFromPercent } from './coerceFromPercent';

export const calculateLoanAmount = (
  purchasePriceOrEstimatedValue: string | undefined,
  loanToValue: string | undefined,
) => {
  const isPossibleToCalculate = !!purchasePriceOrEstimatedValue && !!loanToValue;

  if (isPossibleToCalculate) {
    const coercedPurchasePriceOrEstimatedValue = coerceToIntegerNumber(purchasePriceOrEstimatedValue);
    const coercedLoanToValue = coerceFromPercent(coerceToIntegerNumber(loanToValue)!);

    if (typeof coercedPurchasePriceOrEstimatedValue !== 'number' || typeof coercedLoanToValue !== 'number') {
      return '';
    }

    const result = `$ ${new Intl.NumberFormat().format(
      Math.ceil(coercedPurchasePriceOrEstimatedValue * coercedLoanToValue),
    )}`;

    return result;
  }

  return '';
};
