import React, { FC } from 'react';
import clsx from 'clsx';

import styles from './Input.module.scss';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  readOnly?: boolean;
  label?: string;
}

const Input: FC<InputProps> = ({ className, value, readOnly = false, label, ...restProps }) => (
  <input
    readOnly={readOnly}
    value={value}
    autoComplete="off"
    {...restProps}
    className={clsx(styles.input, className)}
  />
);

export default Input;
