import React, { FC } from 'react';
import { SortingType } from 'hooks/useSorting';
import { ProductVariable } from 'enums/ProductVariable';
import { PassedProductTableHeaderTitle } from 'enums/PassedProductTableHeaderTitle';
import { PassedProgramsData } from 'api/PreQualificationApi';
import ProductHeader from 'components/PrequalificationResults/ProductHeader';
import PassedProductItem from 'components/PrequalificationResults/PassedProductItem';
import NoProducts from 'components/NoProducts';
import { PortalType, settings } from 'settings';

import styles from './PassedProductsTable.module.scss';

export const productTableHeaderTitles = [
  PassedProductTableHeaderTitle.LoanProduct,
  PassedProductTableHeaderTitle.ProductTerms,
  PassedProductTableHeaderTitle.LPC,
  PassedProductTableHeaderTitle.BPC,
  PassedProductTableHeaderTitle.LockTerm,
];

export const retailProductTableHeaderTitles = [
  PassedProductTableHeaderTitle.LoanProduct,
  PassedProductTableHeaderTitle.ProductTerms,
  PassedProductTableHeaderTitle.RatePoints,
  PassedProductTableHeaderTitle.APR,
  PassedProductTableHeaderTitle.LockTerm,
];

interface PassedProductsTableProps {
  sortingType: SortingType<PassedProductTableHeaderTitle>;
  changeSorting: (sortableField: PassedProductTableHeaderTitle) => void;
  sortedItems: PassedProgramsData[];
  onTableRowClick: (id: string) => void;
  portalType: PortalType;
}

const PassedProductsTable: FC<PassedProductsTableProps> = ({
  sortingType,
  changeSorting,
  sortedItems,
  onTableRowClick,
  portalType,
}) => {
  const renderItems = () => {
    const isNoRates = sortedItems.every((item) => item.bpcData.every((lpcData) => lpcData.rate === 0));

    if (!sortedItems.length || isNoRates) {
      return <NoProducts />;
    }

    return (
      <div className={styles.productsContainer}>
        {sortedItems.map((item) => {
          const rates = item.bpcData.map((bpcDataItem) => bpcDataItem.rate);

          if (rates.every((rate) => rate === 0)) {
            return null;
          }

          console.log(item[ProductVariable.BpcBestOption]);

          return (
            <PassedProductItem
              loanProduct={item[ProductVariable.LoanProduct]}
              productTerms={item[ProductVariable.ProductTerms]}
              lpcRate={item.lpcBestOption && item.lpcBestOption.rate}
              bpcRate={item.bpcBestOption?.rate}
              lpcPrice={item.lpcBestOption && item.lpcBestOption.price}
              bpcPrice={item.bpcBestOption?.price}
              bpcPoint={item.bpcBestOption?.point}
              aprPoint={item.aprBestOption.value}
              lockTerm={item[ProductVariable.LockTerm]}
              className={styles.productItem}
              key={item.id}
              onClick={() => onTableRowClick(item.id)}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <ProductHeader
        className={styles.productHeader}
        currentSortingField={sortingType.field}
        onClick={(title) => {
          changeSorting(title as PassedProductTableHeaderTitle);
        }}
        ascending={sortingType.ascending}
        headerTitles={settings[portalType].getPassedProductsTableHeaderTitles()}
        portalType={portalType}
      />
      {renderItems()}
    </div>
  );
};

export default PassedProductsTable;
