import React, { useState } from 'react';
import clsx from 'clsx';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { ReactComponent as SelectArrow } from 'images/dropdown-arrow.svg';
import { ReactComponent as CheckIcon } from 'images/check.svg';

import Input from 'components/Input';
import InputError from 'components/InputError';

import styles from './InputSelect.module.scss';

interface InputSelectProps {
  label: string;
  options: string[];
  value: string | null;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
  errorMessage?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  name: string;
  disabled?: boolean;
}

const InputSelect = ({
  label,
  placeholder,
  options,
  value,
  onChange,
  className,
  errorMessage,
  onBlur,
  name,
  disabled,
}: InputSelectProps) => {
  const [open, setOpen] = useState(false);

  const handleSelect = (option: string) => () => {
    onChange(option);
    setOpen(false);
  };

  const ref = useDetectClickOutside({ onTriggered: () => setOpen(false) });

  const renderOptions = () => (
    <ul className={styles.dropdownOptionsList}>
      {options.map((option, index) => {
        const isOptionSelected = option === value;

        return (
          <div key={index} className={styles.dropdownOptionContainer}>
            <li
              className={clsx(styles.dropdownOption, isOptionSelected && styles.dropdownOptionSelected)}
              onClick={handleSelect(option)}
            >
              {option}
            </li>
            {isOptionSelected && (
              <div className={styles.checkIcon}>
                <CheckIcon />
              </div>
            )}
          </div>
        );
      })}
    </ul>
  );

  return (
    <div className={clsx(styles.inputSelectContainer, className)}>
      <div className={clsx(styles.label, errorMessage && styles.errorLabel)}>{label}</div>
      <div className={styles.dropdownInput} ref={ref}>
        <Input
          placeholder={placeholder}
          className={clsx(styles.inputSelect, !!errorMessage && styles.error)}
          readOnly
          onClick={() => (open ? setOpen(false) : setOpen(true))}
          value={value || ''}
          onBlur={onBlur}
          label={label}
          name={name}
          disabled={disabled}
        />
        <SelectArrow className={clsx(styles.selectArrow, open && styles.selectArrowActive)} />
        {open && renderOptions()}
      </div>
      {!!errorMessage && <InputError className={styles.errorMessage} errorMessage={errorMessage} />}
    </div>
  );
};

export default InputSelect;
