import React, { FC } from 'react';
import clsx from 'clsx';
import { FormikValues } from 'formik';
import { countyByStateList } from 'utils/counyByStateList';
import { PrequalificationInputName } from 'enums/PrequalificationInputName';
import { PrequalificationInputLabel } from 'enums/PrequalificationInputLabel';
import { ProductType } from 'enums/ProductType';
import {
  occupancyTypeOptions,
  propertyTypeOptions,
  vestingTypeOptions,
} from 'components/PrequalificationForm/selectInputOptions';
import { countryStates } from 'components/PrequalificationForm/stateSearchOptions';
import InputSelect from 'components/InputSelect';
import SearchInput from 'components/SearchInput';

import styles from './PropertyInformation.module.scss';

const STATE_MAX_LENGTH = 2;

interface PropertyInformationProps {
  onChange: (inputName: PrequalificationInputName, value: string) => void;
  onSearchInputBlur: (name: string) => void;
  productType: string;
  formikValues: FormikValues;
  getErrorMessage: (inputName: PrequalificationInputName) => string | undefined;
  onBlur: (event: React.FocusEvent) => void;
}

const PropertyInformation: FC<PropertyInformationProps> = ({
  onChange,
  onSearchInputBlur,
  productType,
  formikValues,
  getErrorMessage,
  onBlur,
}) => {
  return (
    <div className={styles.container}>
      <h3 className={styles.sectionTitle}>Property Information</h3>
      <InputSelect
        label={PrequalificationInputLabel.PropertyType}
        onChange={(value) => onChange(PrequalificationInputName.PropertyType, value)}
        value={formikValues[PrequalificationInputName.PropertyType]}
        options={propertyTypeOptions}
        name={PrequalificationInputName.PropertyType}
        errorMessage={getErrorMessage(PrequalificationInputName.PropertyType)}
        placeholder="Select"
        className={styles.formInput}
      />
      <InputSelect
        label={PrequalificationInputLabel.Occupancy}
        onChange={(value) => onChange(PrequalificationInputName.Occupancy, value)}
        value={formikValues[PrequalificationInputName.Occupancy]}
        options={occupancyTypeOptions}
        name={PrequalificationInputName.Occupancy}
        errorMessage={getErrorMessage(PrequalificationInputName.Occupancy)}
        placeholder="Select"
        disabled={productType === ProductType.DebtServiceCoverageRatio}
        className={styles.formInput}
      />
      <InputSelect
        label={PrequalificationInputLabel.Vesting}
        onChange={(value) => onChange(PrequalificationInputName.Vesting, value)}
        value={formikValues[PrequalificationInputName.Vesting]}
        options={vestingTypeOptions}
        name={PrequalificationInputName.Vesting}
        errorMessage={getErrorMessage(PrequalificationInputName.Vesting)}
        placeholder="Select"
        className={styles.formInput}
      />
      <SearchInput
        label={PrequalificationInputLabel.State}
        onChange={(value) => onChange(PrequalificationInputName.State, value)}
        onBlur={onBlur}
        onClear={() => onSearchInputBlur(PrequalificationInputName.State)}
        options={countryStates}
        name={PrequalificationInputName.State}
        placeholder="Type..."
        errorMessage={getErrorMessage(PrequalificationInputName.State)}
        className={styles.formInput}
        value={formikValues[PrequalificationInputName.State]}
        maxLength={STATE_MAX_LENGTH}
        onSelectChange={() => onSearchInputBlur(PrequalificationInputName.State)}
      />

      {formikValues[PrequalificationInputName.State] &&
        countryStates.includes(formikValues[PrequalificationInputName.State]) && (
          <SearchInput
            label={PrequalificationInputLabel.County}
            onChange={(value) => onChange(PrequalificationInputName.County, value)}
            onBlur={onBlur}
            onClear={() => onSearchInputBlur(PrequalificationInputName.County)}
            options={countyByStateList[formikValues[PrequalificationInputName.State]] as string[]}
            name={PrequalificationInputName.County}
            placeholder="Type..."
            errorMessage={getErrorMessage(PrequalificationInputName.County)}
            className={clsx(styles.countyInput, styles.formInput)}
            value={formikValues[PrequalificationInputName.County]}
            onSelectChange={() => onSearchInputBlur(PrequalificationInputName.County)}
          />
        )}
    </div>
  );
};

export default PropertyInformation;
