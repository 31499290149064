import * as Sentry from '@sentry/react';
import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import rootReducer, { RootState } from 'handlers';
import saveApplicationDataMiddleware from 'middlewares/saveApplicationData';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state: RootState) => {
    return {
      decisionId: state.prequalificationResults.decisionId,
    };
  },
});

export const history = createBrowserHistory();

const store = configureStore({
  reducer: rootReducer,
  enhancers: (defaultEnhancers) => {
    return defaultEnhancers.concat(sentryReduxEnhancer);
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(saveApplicationDataMiddleware);
  },
});

export type AppDispatch = typeof store.dispatch;

export default store;
