import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { PriceAdjustment, PricesToRates } from 'api/PreQualificationApi';
import { SortingType } from 'hooks/useSorting';
import { ProductTerm } from 'enums/ProductTerm';

import RatesAndPricesTable from 'components/PrequalificationResultDetails/RatesAndPricesTable';
import LlpaData from 'components/PrequalificationResultDetails/LlpaData';
import Popup from 'components/Popup';
import PopupHeader from 'components/Popup/PopupHeader';

import { Theme } from 'components/PrequalificationResultPDF/PrequalificationResultPDF';

import { PricesToRatesWithApr } from 'components/PrequalificationResultDetails/PrequalificationResultDetails';

import styles from './Pricing.module.scss';

interface PricingProps {
  onSort: (title: string) => void;
  changeSelectedRow: (id: string) => void;
  sortedItems: PricesToRatesWithApr[] | PricesToRates[];
  sortingType: SortingType<string>;
  currentPriceOption: PricesToRates;
  priceAdjustmentsList: PriceAdjustment[];
  currentProductTerm: ProductTerm;
  bestPriceOption?: PricesToRates;
  isRatePoints?: boolean;
  lenderPaidCompensation?: number;
  colors?: Theme | null;
  rowClassName?: string;
}

const Pricing: FC<PricingProps> = ({
  sortedItems,
  onSort,
  changeSelectedRow,
  sortingType,
  bestPriceOption,
  currentPriceOption,
  lenderPaidCompensation,
  priceAdjustmentsList,
  currentProductTerm,
  isRatePoints,
  colors,
  rowClassName,
}) => {
  const [llpaOpen, setLlpaOpen] = useState(false);

  const handleRowClick = (id: string) => {
    setLlpaOpen(true);
    changeSelectedRow(id);
  };

  return (
    <div className={styles.content}>
      <div className={styles.tableContainer}>
        <RatesAndPricesTable
          sortedItems={sortedItems}
          onSort={onSort}
          currentSortingField={sortingType.field}
          ascending={sortingType.ascending}
          title={`Rate/${isRatePoints ? 'Points' : 'Price'}`}
          bestPriceOption={bestPriceOption}
          onTableRowClick={handleRowClick}
          currentRow={currentPriceOption}
          llpaOpen={llpaOpen}
          isRatePoints={isRatePoints}
          colors={colors}
          rowClassName={rowClassName}
        />
        {currentPriceOption && (
          <Popup className={clsx(styles.popup, llpaOpen && styles.popupActive, styles.llpsDisablePopupOnDesktop)}>
            <p className={styles.desktopTitle}>LLPA’s</p>
            <div className={styles.llpaContainer}>
              <PopupHeader className={styles.popupHeader} onPopupClose={() => setLlpaOpen(false)} title="LLPA’s" />
              <LlpaData
                lenderPaidCompensation={lenderPaidCompensation}
                priceAdjustmentsList={priceAdjustmentsList}
                basePrice={currentPriceOption.defaultBasePrice}
                netPrice={currentPriceOption.price}
                key={currentPriceOption.id}
                cap={currentPriceOption.cap}
                currentProductTerm={currentProductTerm}
                points={currentPriceOption.point}
              />
            </div>
          </Popup>
        )}
      </div>
    </div>
  );
};

export default Pricing;
