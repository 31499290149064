import Redux, { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import { SessionStorageKeyName } from 'enums/SessionStorageKeyName';
import { RootState } from 'handlers';
import { SavedPreQualificationResultsState } from 'handlers/prequalificationResults';

const saveApplicationDataMiddleware: Redux.Middleware =
  ({ getState }: MiddlewareAPI) =>
  (next: Dispatch) =>
  (action: AnyAction) => {
    const result = next(action);

    const applicationState: RootState = getState();

    if (action.type === 'preQualificationForm/setPreQualificationFormData') {
      sessionStorage.setItem(
        SessionStorageKeyName.PreQualificationForm,
        JSON.stringify(applicationState.preQualificationForm),
      );

      const prequalificationResult: SavedPreQualificationResultsState = {
        filters: applicationState.prequalificationResults.filters,
        passedPrograms: applicationState.prequalificationResults.passedPrograms,
        declinedPrograms: applicationState.prequalificationResults.declinedPrograms,
        decisionId: applicationState.prequalificationResults.decisionId,
      };

      sessionStorage.setItem(SessionStorageKeyName.PrequalificationResults, JSON.stringify(prequalificationResult));
    }

    if (action.type === 'preQualificationResultDetails/setCurrentProductId') {
      sessionStorage.setItem(
        SessionStorageKeyName.CurrentProductId,
        JSON.stringify(applicationState.prequalificationResultDetails.currentProductId),
      );
    }

    if (action.type === 'preQualificationResultDetails/setFilterAvailableValues') {
      sessionStorage.setItem(
        SessionStorageKeyName.FilterAvailableValues,
        JSON.stringify(applicationState.prequalificationResultDetails.filterAvailableValues),
      );
    }

    if (action.type === 'preQualificationResultDetails/setPricesAndRatesFilter') {
      sessionStorage.setItem(
        SessionStorageKeyName.PrequalificationResultsFilter,
        JSON.stringify(applicationState.prequalificationResultDetails.filter),
      );
    }

    return result;
  };

export default saveApplicationDataMiddleware;
