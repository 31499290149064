import React, { FC } from 'react';
import clsx from 'clsx';
import { ReactComponent as TooltipIcon } from 'images/tip-icon.svg';

import styles from './Tooltip.module.scss';

interface TooltipProps {
  label: string;
  className?: string;
}

const Tooltip: FC<TooltipProps> = ({ label, className }) => (
  <div className={clsx(styles.container, className)}>
    <TooltipIcon />
    <div className={styles.label}>{label}</div>
  </div>
);

export default Tooltip;
