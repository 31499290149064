import React, { FC } from 'react';
import clsx from 'clsx';
import { ButtonType } from 'components/Button/Button';
import Button from 'components/Button';

import styles from './FilterButtons.module.scss';

interface FilterButtonsProps {
  resetButtonDisabled: boolean;
  handleResetFilters: () => void;
  applyButtonDisabled: boolean;
  handleApply: () => void;
  isResetLoading?: boolean;
  isApplyLoading?: boolean;
}

const FilterButtons: FC<FilterButtonsProps> = ({
  resetButtonDisabled,
  handleResetFilters,
  applyButtonDisabled,
  handleApply,
  isResetLoading,
  isApplyLoading,
}) => (
  <div className={styles.buttonsContainer}>
    <Button
      disabled={applyButtonDisabled}
      className={clsx(styles.button, styles.applyButton)}
      onClick={handleApply}
      isLoading={isApplyLoading}
    >
      Apply
    </Button>
    <Button
      disabled={resetButtonDisabled}
      type={ButtonType.Secondary}
      className={clsx(styles.button, styles.resetButton)}
      onClick={handleResetFilters}
      isLoading={isResetLoading}
    >
      Reset
    </Button>
  </div>
);

export default FilterButtons;
