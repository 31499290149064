import React from 'react';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from 'handlers';
import InnerPageLayout from 'components/InnerPageLayout';
import PrequalificationResults from 'components/PrequalificationResults';

const Results = () => {
  const { passedPrograms, declinedPrograms, isFilterOpen, filters } = useSelector(
    (state: RootState) => state.prequalificationResults,
  );

  if (!passedPrograms && !declinedPrograms) {
    return <Redirect to="/" />;
  }

  return (
    <InnerPageLayout>
      <PrequalificationResults
        passedProgramsData={passedPrograms!}
        declinedProgramsData={declinedPrograms!}
        isFilterOpen={isFilterOpen}
        filters={filters}
      />
    </InnerPageLayout>
  );
};

export default Results;
