import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SessionStorageKeyName } from 'enums/SessionStorageKeyName';
import { getSessionStorageItem } from 'utils/getSessionStorageItem';

export interface ProductDetailsFilterState {
  lpcRateRange: number[] | null;
  lpcPriceRange: number[] | null;
  bpcRateRange: number[];
  bpcPriceRange: number[];
  bpcPointsRange: number[] | null;
}

export interface PreQualificationResultDetailsState {
  filter: ProductDetailsFilterState | undefined;
  filterAvailableValues: ProductDetailsFilterState | undefined;
  currentProductId: string | null;
  isFilterOpen: boolean;
}

const filterAvailableValuesInitialState: ProductDetailsFilterState = getSessionStorageItem(
  SessionStorageKeyName.FilterAvailableValues,
);
const currentProductIdInitialState: string = getSessionStorageItem(SessionStorageKeyName.CurrentProductId);
const filterInitialState: ProductDetailsFilterState = getSessionStorageItem(
  SessionStorageKeyName.PrequalificationResultsFilter,
);

const initialState: PreQualificationResultDetailsState = {
  filter: filterInitialState ?? undefined,
  filterAvailableValues: filterAvailableValuesInitialState ?? undefined,
  currentProductId: currentProductIdInitialState ?? null,
  isFilterOpen: false,
};

const preQualificationResultDetailsReducer = createSlice({
  name: 'preQualificationResultDetails',
  initialState,
  reducers: {
    setCurrentProductId: (state: PreQualificationResultDetailsState, { payload }: PayloadAction<string>) => {
      state.currentProductId = payload;
    },
    setPricesAndRatesFilter: (
      state: PreQualificationResultDetailsState,
      { payload }: PayloadAction<ProductDetailsFilterState>,
    ) => {
      state.filter = payload;
    },
    setPricesAndRatesFilterOpen: (state: PreQualificationResultDetailsState, { payload }: PayloadAction<boolean>) => {
      state.isFilterOpen = payload;
    },
    setFilterAvailableValues: (
      state: PreQualificationResultDetailsState,
      { payload }: PayloadAction<ProductDetailsFilterState>,
    ) => {
      state.filterAvailableValues = payload;
    },
  },
});

export const { setCurrentProductId, setPricesAndRatesFilter, setPricesAndRatesFilterOpen, setFilterAvailableValues } =
  preQualificationResultDetailsReducer.actions;

export default preQualificationResultDetailsReducer.reducer;
