export enum PropertyType {
  SfrOrPud = 'SFR/PUD',
  WarrantableCondo = 'Warrantable Condo',
  NonWarrantableCondo = 'Non-Warrantable Condo',
  TwoUnit = '2 Unit',
  ThreeUnit = '3 Unit',
  FourUnit = '4 Unit',
  FiveToEightUnit = '5-8 Unit',
  MixedUse = 'Mixed Use',
}
