import { PassedProductTableHeaderTitle } from 'enums/PassedProductTableHeaderTitle';
import { ProductVariable } from 'enums/ProductVariable';
import { PassedProgramsData } from 'api/PreQualificationApi';

type FieldsWithRateAndPrice = PassedProductTableHeaderTitle.LPC | PassedProductTableHeaderTitle.BPC;

const getVariableName = (
  field: FieldsWithRateAndPrice | PassedProductTableHeaderTitle.RatePoints,
) => {
  switch (field) {
    case PassedProductTableHeaderTitle.LPC:
      return ProductVariable.LpcBestOption;

    case PassedProductTableHeaderTitle.BPC:
      return ProductVariable.BpcBestOption;

    case PassedProductTableHeaderTitle.RatePoints:
      return ProductVariable.BpcBestOption;

    default:
      return null as never;
  }
};

export const createCommissionSortingHandler =
  <SortItem extends PassedProgramsData>(
    field: FieldsWithRateAndPrice | PassedProductTableHeaderTitle.RatePoints | PassedProductTableHeaderTitle.APR,
    ascending: boolean,
  ) =>
  (firstItem: SortItem, secondItem: SortItem) => {
    if (field === PassedProductTableHeaderTitle.APR) {
      if (firstItem[ProductVariable.aprBestOption].value <= secondItem[ProductVariable.aprBestOption].value) {
        return ascending ? -1 : 1;
      }

      if (firstItem[ProductVariable.aprBestOption].value > secondItem[ProductVariable.aprBestOption].value) {
        return ascending ? 1 : -1;
      }
    }

    if (field === PassedProductTableHeaderTitle.RatePoints) {
      if (firstItem[getVariableName(field)].rate < secondItem[getVariableName(field)].rate) {
        return ascending ? -1 : 1;
      }

      if (firstItem[getVariableName(field)].rate > secondItem[getVariableName(field)].rate) {
        return ascending ? 1 : -1;
      }
    }

    if (
      `${firstItem[getVariableName(field as FieldsWithRateAndPrice)].rate}/${firstItem[getVariableName(field as FieldsWithRateAndPrice)].price}` <
      `${secondItem[getVariableName(field as FieldsWithRateAndPrice)].rate}/${secondItem[getVariableName(field as FieldsWithRateAndPrice)].price}`
    ) {
      return ascending ? -1 : 1;
    }

    if (
      `${firstItem[getVariableName(field as FieldsWithRateAndPrice)].rate}/${firstItem[getVariableName(field as FieldsWithRateAndPrice)].price}` >
      `${secondItem[getVariableName(field as FieldsWithRateAndPrice)].rate}/${secondItem[getVariableName(field as FieldsWithRateAndPrice)].price}`
    ) {
      return ascending ? 1 : -1;
    }

    return 0;
  };
