import React from 'react';

import styles from './Footer.module.scss';

const DISCLAIMER_TEXT = <>This website is intended for real estate broker or mortgage broker professionals and is not intended for consumers. <br /> This website does not constitute an offer or solicitation or advertisement (as defined by Section 1026.2(a)(2) of Regulation Z) to a consumer for a consumer mortgage loan product or to the general public. Rates provided are for investment property/business purpose loans only. Such rates are indicative and are determined after a loan application is submitted, a rate is locked and a lock confirmation is provided. Loans are not offered in all states. For states we are licensed to do business contact your account executive or go to <a target="_blank" rel="noreferrer" className={styles.link} href="https://nmlsconsumeraccess.org">www.nmlsconsumeraccess.org</a> NMLS # 127722. Equal Housing Lender.</>;

const WholesaleDisclaimer = () => {
  return <p>{DISCLAIMER_TEXT}</p>;
};

export default WholesaleDisclaimer;
