import React, { FC } from 'react';
import clsx from 'clsx';

import { Theme } from 'components/PrequalificationResultPDF/PrequalificationResultPDF';

import styles from './BestPriceLabel.module.scss';

interface BestPriceLabelProps {
  className?: string;
  colors?: Theme | null;
}

const BestPriceLabel: FC<BestPriceLabelProps> = ({ className, colors }) => (
  <div 
    className={clsx(styles.container, className)} 
    style={colors ? {background: colors.primary100} : {}}
  >Best Price</div>
);

export default BestPriceLabel;
