export enum IncomeDocumentType {
  OneYearFullDoc = '1 Year Full Doc',
  TwoYearFullDoc = '2 Year Full Doc',
  TwelveMonthsBankStatements = '12 Months Bank Statements',
  TwentyFourMonthsBankStatements = '24 Months Bank Statements',
  OneYear1099 = '1 Year 1099',
  TwoYear1099 = '2 Year 1099',
  AssetQualification = 'Asset Qualification',
  OneYearPL = '1 Year P&L',
  TwoYearPL = '2 Year P&L',
}
