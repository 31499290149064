import React, { FC } from 'react';
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';
import clsx from 'clsx';

import InputError from 'components/InputError';
import Tooltip from 'components/Tooltip';

import styles from './NumberInput.module.scss';

const DECIMAL_PLACES_AMOUNT = 0;

interface NumberInputProps extends NumberFormatProps {
  label?: string;
  errorMessage?: string;
  decimalPlacesAmount?: number;
  disabled?: boolean;
  value?: string;
  isAllowed?: (values: NumberFormatValues) => boolean;
  format?: string;
  tooltip?: string;
  subLabel?: string;
}

const NumberInput: FC<NumberInputProps> = ({
  prefix,
  label,
  placeholder,
  className,
  allowNegative,
  thousandSeparator,
  suffix,
  errorMessage,
  name,
  maxLength,
  max,
  disabled,
  value,
  isAllowed,
  format,
  decimalPlacesAmount = DECIMAL_PLACES_AMOUNT,
  tooltip,
  subLabel,
  onChange,
  onBlur,
}) => (
  <div className={clsx(styles.numberInput, className)}>
    <div className={clsx(styles.inputContainer)}>
      <div className={styles.labelContainer}>
        {label && (
          <div className={clsx(styles.label, errorMessage && styles.errorLabel)}>
            {label} {tooltip && <Tooltip label={tooltip} className={styles.tooltip} />}
          </div>
        )}
        <span className={styles.subLabel}>{subLabel}</span>
      </div>
      <NumberFormat
        className={clsx(styles.input, !!errorMessage && styles.error)}
        prefix={prefix}
        suffix={suffix}
        onChange={onChange}
        placeholder={placeholder}
        allowNegative={allowNegative}
        thousandSeparator={thousandSeparator}
        decimalScale={decimalPlacesAmount}
        onBlur={onBlur}
        name={name}
        maxLength={maxLength}
        max={max}
        disabled={disabled}
        value={value}
        isAllowed={isAllowed}
        format={format}
      />
    </div>
    {!!errorMessage && <InputError className={styles.errorMessage} errorMessage={errorMessage} />}
  </div>
);

export default NumberInput;
