import { PricesToRates } from 'api/PreQualificationApi';

export const createPriceAndRatesSortingHandler =
  (field: string, ascending: boolean) => (firstItem: PricesToRates, secondItem: PricesToRates) => {
    if (firstItem.rate < secondItem.rate) {
      return ascending ? -1 : 1;
    }

    if (firstItem.rate > secondItem.rate) {
      return ascending ? 1 : -1;
    }

    return 0;
  };
