import { PassedProgramsData, PricesToRates } from 'api/PreQualificationApi';
import DropdownVariable from 'components/Dropdown/DropdownVariable';
import DisclaimerBox from 'components/PrequalificationResultDetails/DisclaimerBox';
import Pricing from 'components/PrequalificationResultDetails/Pricing';
import { ProductVariable } from 'enums/ProductVariable';
import { DateTime } from 'luxon';
import React, { FC } from 'react';
import { Theme } from 'components/PrequalificationResultPDF/PrequalificationResultPDF';

import clsx from 'clsx';

import styles from './ProductDetails.module.scss';

interface ProductDetailsProps {
  colors: Theme | null;
  lpcItems: PricesToRates[] | null;
  bpcItems: PricesToRates[];
  currentProgram: PassedProgramsData;
  currentBpcPriceOption: PricesToRates;
  currentLpcPriceOption: PricesToRates | null;
  changeSelectedBpcRow: (id: string) => void;
  changeSelectedLpcRow: (id: string) => void;
  rowClassName?: string;
}

const MAX_ROWS_COUNT_ON_PAGE = 26;
const MAX_ROWS_COUNT_ON_PAGE_WITH_DISCLAIMER_BOX = 22;

const ProductDetails: FC<ProductDetailsProps> = ({
  colors,
  lpcItems,
  bpcItems,
  currentProgram,
  currentBpcPriceOption,
  currentLpcPriceOption,
  changeSelectedBpcRow,
  changeSelectedLpcRow,
  rowClassName,
}) => {
  const date = DateTime.now().setZone('America/New_York').toFormat('MM/dd/yyyy h:mm:ss a');
  const sortingType = {
    field: 'Rate/Price',
    ascending: true,
  };

  const showDisclaimerBox = Object.values(currentProgram.productDisclaimers).some((item) => !!item);

  const itemsOnPage = lpcItems ? Math.max(lpcItems.length, bpcItems.length) : bpcItems.length;

  return (
    <div className={styles.container}>
      <div className={styles.pageInfo}>
        <p>Pricing Results</p>
        <p>{date} EST</p>
      </div>
      <h1 
        className={styles.header} 
        style={colors ? {color: colors.primary100} : {}} // global style variables don't work inside print window
      >
          Product Details
      </h1>
      <div className={styles.termsBoxContainer}>
        <DropdownVariable title="Loan Product" value={currentProgram[ProductVariable.LoanProduct]} />
        <DropdownVariable title="Product Terms" value={currentProgram[ProductVariable.ProductTerms]} />
        <DropdownVariable title="Lock Term" value={currentProgram[ProductVariable.LockTerm]} />
      </div>
      <div className={clsx(lpcItems ? styles.pricingContainer : styles.singlePricingContainer)}>
        {lpcItems && (
          <div className={styles.content}>
            <p className={styles.contentSectionTitle}>Lender Paid Compensation (LPC)</p>
            <Pricing
              sortedItems={lpcItems}
              onSort={() => {}}
              sortingType={sortingType}
              bestPriceOption={currentProgram.lpcBestOption}
              lenderPaidCompensation={currentProgram.lenderPaidCompensation}
              priceAdjustmentsList={currentProgram.priceAdjustmentsList}
              currentProductTerm={currentProgram[ProductVariable.ProductTerms]}
              changeSelectedRow={changeSelectedLpcRow}
              currentPriceOption={currentLpcPriceOption!}
              colors={colors}
            />
          </div>
        )}
        <div className={styles.content}>
          <p className={styles.contentSectionTitle}>{lpcItems ? 'Borrower Paid Compensation (BPC)' : 'Pricing'}</p>
          <Pricing
            sortedItems={bpcItems}
            onSort={() => {}}
            sortingType={sortingType}
            bestPriceOption={currentProgram.bpcBestOption}
            priceAdjustmentsList={currentProgram.priceAdjustmentsList}
            currentProductTerm={currentProgram[ProductVariable.ProductTerms]}
            changeSelectedRow={changeSelectedBpcRow}
            currentPriceOption={currentBpcPriceOption}
            isRatePoints={!lpcItems}
            colors={colors}
            rowClassName={rowClassName}
          />
        </div>
      </div>
      {itemsOnPage >= MAX_ROWS_COUNT_ON_PAGE_WITH_DISCLAIMER_BOX && itemsOnPage <= MAX_ROWS_COUNT_ON_PAGE && (
        <div className={styles.pageBreak} />
      )}
      {showDisclaimerBox && <DisclaimerBox className={styles.disclaimerBox} disclaimers={currentProgram.productDisclaimers} />}
    </div>
  );
};

export default ProductDetails;
