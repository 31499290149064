import React, { FC, useContext } from 'react';
import CreditProfile from 'components/PrequalificationForm/CreditProfile';
import LoanCriteria from 'components/PrequalificationForm/LoanCriteria';
import PropertyInformation from 'components/PrequalificationForm/PropertyInformation';
import Footer from 'components/Footer';
import LenderInformation from 'components/PrequalificationForm/LenderInformation';
import { ProductType } from 'enums/ProductType';
import { Application } from 'handlers/prequalificationForm';
import { PortalContext, PortalParamsType } from 'index';
import { settings } from 'settings';
import { Theme } from 'components/PrequalificationResultPDF/PrequalificationResultPDF';

import styles from './SubmissionForm.module.scss';

interface AlternativeDocumentationProps {
  values: Application;
  productType: ProductType;
  colors: Theme | null;
}

const SubmissionForm: FC<AlternativeDocumentationProps> = ({ values, productType, colors }) => {
  // No functionality is required for printing only
  const { portalType } = useContext(PortalContext) as PortalParamsType;
  const showDebtToIncome =
    productType === ProductType.AlternativeDocumentation || productType === ProductType.FullDocumentation;

  const showDscr = productType === ProductType.DebtServiceCoverageRatio;

  const showIncomeDocumentType =
    productType === ProductType.AlternativeDocumentation || productType === ProductType.FullDocumentation;

  return (
    <div className={styles.container}>
      <div className={styles.pageInfo}>
        <p>Loan Terms:</p>
      </div>
      <h1
        className={styles.header}
        style={colors ? { color: colors.primary100 } : {}} // global style variables don't work inside print window
      >
        {productType}
      </h1>
      <div className={styles.loanCriteria}>
        <LoanCriteria
          onChange={() => {}}
          showDebtToIncome={showDebtToIncome}
          showDscr={showDscr}
          showIncomeDocumentType={showIncomeDocumentType}
          validatePercentInput={() => false}
          formikValues={values}
          productType={productType}
          getErrorMessage={() => ''}
        />
      </div>
      <div className={styles.bottomBar}>
        <div className={styles.creditProfile}>
          <CreditProfile onChange={() => {}} formikValues={values} getErrorMessage={() => ''} />
        </div>
        <div className={styles.propertyInformation}>
          <PropertyInformation
            onChange={() => {}}
            productType={productType}
            onSearchInputBlur={() => {}}
            formikValues={values}
            getErrorMessage={() => ''}
            onBlur={() => {}}
          />
        </div>
      </div>
      {settings[portalType].showLenderInformationOnSubmissionFormPdf && (
        <div className={styles.bottomBar}>
          <LenderInformation onChange={() => {}} formikValues={values} getErrorMessage={() => ''} />
        </div>
      )}
      {settings[portalType].showFooterOnSubmissionFormPdf && <Footer />}
    </div>
  );
};

export default SubmissionForm;
