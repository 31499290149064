import { PricesToRates } from 'api/PreQualificationApi';

const MIN_VALUE_INDEX = 0;
const MAX_VALUE_INDEX = 1;

export const filterPricesAndRates = (
  item: PricesToRates,
  rateRange: number[],
  priceRange: number[],
  pointsRange: number[] | null,
) => {
  const getPointsFilter = () => {
    if (pointsRange) {
      return item.point >= pointsRange[MIN_VALUE_INDEX] && item.point <= pointsRange[MAX_VALUE_INDEX];
    }

    return item;
  };

  return (
    item.rate >= rateRange[MIN_VALUE_INDEX] &&
    item.rate <= rateRange[MAX_VALUE_INDEX] &&
    item.price >= priceRange[MIN_VALUE_INDEX] &&
    item.price <= priceRange[MAX_VALUE_INDEX] &&
    getPointsFilter()
  );
};
