export enum StrategyName {
  AssetQualification = 'Asset Qualification',
  BankStatement = 'Bank Statement',
  DSCR = 'DSCR 1:1',
  DSCRLowRatio = 'Low Ratio',
  ForeignNational = 'Foreign National',
  FullDocumentation = 'Full Documentation',
  DscrNoRatio = 'No Ratio',
  DscrElite = 'Debt Service Coverage Elite',
  MixedUse = 'Mixed Use / 5+',
  ProfitLossOnly = 'Profit & Loss Only',
}
