import React, { FC } from 'react';
import clsx from 'clsx';
import { ReactComponent as CloseIcon } from 'images/close-icon.svg';

import styles from './PopupHeader.module.scss';

interface FilterHeaderProps {
  onPopupClose: () => void;
  title: string;
  className?: string;
}

const PopupHeader: FC<FilterHeaderProps> = ({ onPopupClose, title, className }) => (
  <div className={clsx(styles.headerContainer, className)}>
    <h3 className={styles.header}>{title}</h3>
    <CloseIcon className={styles.closeIcon} onClick={onPopupClose} />
  </div>
);

export default PopupHeader;
