import React, { FC } from 'react';
import { PortalType } from 'settings';
import { ProductVariable } from 'enums/ProductVariable';
import { DeclinedProductTableHeaderTitle } from 'enums/DeclinedProductTableHeaderTitle';
import { SortingType } from 'hooks/useSorting';
import { DeclinedProgramsData } from 'handlers/prequalificationResults';
import ProductHeader from 'components/PrequalificationResults/ProductHeader';
import DeclinedProductItem from 'components/PrequalificationResults/DeclinedProductItem';
import NoProducts from 'components/NoProducts';

import styles from './DeclinedProductsTable.module.scss';

const productTableHeaderTitles = [
  DeclinedProductTableHeaderTitle.LoanProduct,
  DeclinedProductTableHeaderTitle.DeclineReason,
];

interface DeclinedProductsTableProps {
  sortingType: SortingType<DeclinedProductTableHeaderTitle>;
  changeSorting: (sortableField: DeclinedProductTableHeaderTitle) => void;
  sortedItems: DeclinedProgramsData[];
  portalType: PortalType;
}

const DeclinedProductsTable: FC<DeclinedProductsTableProps> = ({
  sortingType,
  changeSorting,
  sortedItems,
  portalType,
}) => {
  const renderItems = () => {
    if (!sortedItems.length) {
      return <NoProducts />;
    }

    return (
      <div className={styles.productsContainer}>
        {sortedItems.map((item) => (
          <DeclinedProductItem
            loanProduct={item[ProductVariable.LoanProduct]}
            declineReasons={item[ProductVariable.DeclineReasons]}
            key={item[ProductVariable.LoanProduct]}
          />
        ))}
      </div>
    );
  };

  return (
    <div>
      <ProductHeader
        className={styles.productHeader}
        currentSortingField={sortingType.field}
        onClick={(title) => {
          changeSorting(title as DeclinedProductTableHeaderTitle);
        }}
        ascending={sortingType.ascending}
        headerTitles={productTableHeaderTitles}
        portalType={portalType}
      />
      {renderItems()}
    </div>
  );
};

export default DeclinedProductsTable;
