import React, { FC } from 'react';
import { ProductType } from 'enums/ProductType';
import Footer from 'components/Footer';
import PrequalificationForm from 'components/PrequalificationForm';

import styles from './HomeRightBar.module.scss';

interface HomeRightBarProps {
  productType: ProductType;
}

const HomeRightBar: FC<HomeRightBarProps> = ({ productType }) => (
  <>
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <h1 className={styles.title}>{productType}</h1>
        <PrequalificationForm productType={productType} />
      </div>
      <Footer />
    </div>
  </>
);

export default HomeRightBar;
