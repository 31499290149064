import React, { FC, ReactElement } from 'react';
import clsx from 'clsx';

import Loader from 'components/Loader';

import styles from './Button.module.scss';

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Gradient = 'gradient',
  GradientWithNoIcon = 'gradientWithNoIcon',
}

interface ButtonProps {
  onClick?: () => void;
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  type?: ButtonType;
  disabledForRequest?: boolean;
  active?: boolean;
  icon?: ReactElement;
}

const getButtonStyles = (buttonType: ButtonType) => {
  switch (buttonType) {
    case ButtonType.Primary:
      return styles.primary;

    case ButtonType.Secondary:
      return styles.secondary;

    case ButtonType.Gradient:
      return styles.gradient;

    case ButtonType.GradientWithNoIcon:
      return styles.gradientWithNoIcon;
    default:
      return null as never;
  }
};

const getLoaderColor = (buttonType: ButtonType) => {
  switch (buttonType) {
    case ButtonType.Secondary:
      return '#CCD7E8';

    case ButtonType.Gradient:
      return '#00378C';

    case ButtonType.GradientWithNoIcon:
      return '#00378C';

    default:
      return '#FFFFFF';
  }
};

const Button: FC<ButtonProps> = ({
  children,
  onClick,
  className,
  isLoading,
  disabled,
  type = ButtonType.Primary,
  disabledForRequest,
  active,
  icon,
}) => (
  <button
    type="button"
    className={clsx(
      styles.button,
      getButtonStyles(type),
      className,
      isLoading && styles.isLoading,
      disabledForRequest && styles.disabledForRequest,
      active && styles.active,
    )}
    onClick={onClick}
    disabled={disabled || isLoading}
  >
    {isLoading ? (
      <Loader isButtonLoader loaderColor={getLoaderColor(type)} />
    ) : (
      <>
        {icon && icon} <p className={clsx(styles.title, icon && styles.titleWithIcon)}>{children}</p>
      </>
    )}
  </button>
);

export default Button;
