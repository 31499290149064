import React, { FC } from 'react';
import clsx from 'clsx';
import { ReactComponent as ArrowDown } from 'images/arrow-down.svg';
import { PricesToRates } from 'api/PreQualificationApi';
import { coerceNumberToDisplayValue } from 'utils/coerceNumberToDisplayValue';
import BestPriceLabel from 'components/PrequalificationResultDetails/BestPriceLabel';

import { Theme } from 'components/PrequalificationResultPDF/PrequalificationResultPDF';

import { PricesToRatesWithApr } from 'components/PrequalificationResultDetails/PrequalificationResultDetails';

import { PassedProductTableHeaderTitle } from 'enums/PassedProductTableHeaderTitle';

import styles from './RatesAndPricesTable.module.scss';

interface RatesAndPricesTableProps {
  onSort: (title: string) => void;
  ascending: boolean;
  currentSortingField: string;
  title: string;
  sortedItems: PricesToRatesWithApr[] | PricesToRates[];
  onTableRowClick: (id: string) => void;
  llpaOpen: boolean;
  currentRow: PricesToRates;
  isRatePoints?: boolean;
  colors?: Theme | null;
  rowClassName?: string;
  bestPriceOption?: PricesToRates;
}

const RatesAndPricesTable: FC<RatesAndPricesTableProps> = ({
  onSort,
  ascending,
  currentSortingField,
  title,
  sortedItems,
  bestPriceOption,
  onTableRowClick,
  currentRow,
  llpaOpen,
  isRatePoints,
  colors,
  rowClassName,
}) => {
  const getPointClassName = (value: number, isCurrent: boolean) => {
    const className = isCurrent ? styles.negativePointCurrent : styles.negativePoint;

    if (!llpaOpen || (llpaOpen && !isCurrent)) {
      return Number(value) < 0 && className;
    }
  };

  const renderRows = () =>
    sortedItems.map((dataItem) => {
      return (
        <div
          className={clsx(
            styles.rowDataContainer,
            currentRow.id === dataItem.id && styles.rowDataContainerSelected,
            currentRow.id === dataItem.id && llpaOpen && styles.rowDataContainerSelectedMobil,
          )}
          style={
            currentRow.id === dataItem.id && colors
              ? {
                  background: `linear-gradient(180deg, ${colors.gradientFirstColor} 0%, ${colors.gradientSecondColor} 100%)`,
                }
              : {}
          }
          key={dataItem.id}
          onClick={() => onTableRowClick(dataItem.id)}
        >
          <div className={clsx(styles.rowContainer, rowClassName)}>
            <span>{coerceNumberToDisplayValue(dataItem.rate)}</span>/
            <span className={clsx(isRatePoints && getPointClassName(dataItem.point, currentRow.id === dataItem.id))}>
              {coerceNumberToDisplayValue(isRatePoints ? dataItem.point : dataItem.price)}
            </span>
          </div>
          {dataItem.id === bestPriceOption?.id && (
            <BestPriceLabel
              className={isRatePoints ? styles.bestPriceLabelRetail : styles.bestPriceLabel}
              colors={colors}
            />
          )}
          {isRatePoints && (
            <div className={clsx(styles.rowContainer, rowClassName)}>
              <span>{coerceNumberToDisplayValue((dataItem as PricesToRatesWithApr).apr)}%</span>
            </div>
          )}
        </div>
      );
    });

  return (
    <div>
      <div className={styles.tableHeader}>
        <div className={styles.titleContainer} onClick={() => onSort(title)}>
          <p className={clsx(styles.title)}>{title}</p>
          <ArrowDown className={clsx(ascending && title === currentSortingField && styles.arrowRotated)} />
        </div>
        {isRatePoints && (
          <div className={styles.titleContainer} onClick={() => onSort(PassedProductTableHeaderTitle.APR)}>
            <p className={clsx(styles.title)}>{PassedProductTableHeaderTitle.APR}</p>
            <ArrowDown
              className={clsx(
                ascending && PassedProductTableHeaderTitle.APR === currentSortingField && styles.arrowRotated,
              )}
            />
          </div>
        )}
      </div>
      <div>{renderRows()}</div>
    </div>
  );
};
export default RatesAndPricesTable;
