import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'handlers';
import HomeLeftBar from 'components/HomeLeftBar';
import HomeRightBar from 'components/HomeRightBar';

import { ProductType } from 'enums/ProductType';

import styles from './Home.module.scss';

const Home = () => {
  const { productType } = useSelector((state: RootState) => state.preQualificationForm);

  const [currentProduct, setCurrentProduct] = useState<ProductType>(productType);

  return (
    <div className={styles.pageContainer}>
      <HomeLeftBar onChange={(value) => setCurrentProduct(value)} currentProduct={currentProduct} />
      <HomeRightBar productType={currentProduct} />
    </div>
  );
};

export default Home;
