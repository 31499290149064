import React, { useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import clsx from 'clsx';

import { ReactComponent as SearchIcon } from 'images/search-icon.svg';
import { ReactComponent as CloseIcon } from 'images/close-icon.svg';

import Input from 'components/Input';
import InputError from 'components/InputError';

import styles from './SearchInput.module.scss';

interface InputSelectProps {
  label: string;
  onChange: (value: string) => void;
  onSelectChange: () => void;
  onClear?: () => void;
  options: string[];
  name: string;
  placeholder?: string;
  className?: string;
  errorMessage?: string;
  onBlur: (event: React.FocusEvent) => void;
  disabled?: boolean;
  value: string;
  maxLength?: number;
}

const SearchInput = ({
  label,
  onChange,
  onSelectChange,
  onClear,
  placeholder,
  options,
  className,
  errorMessage,
  onBlur,
  name,
  disabled,
  value,
  maxLength,
}: InputSelectProps) => {
  const [open, setOpen] = useState(false);

  const handleSelect = (option: string) => () => {
    onChange(option);
    setOpen(false);
    onSelectChange();
  };

  const ref = useDetectClickOutside({ onTriggered: () => setOpen(false) });

  const renderOptions = () => {
    if (!value) {
      return;
    }

    const result = options.filter((item) => item.toLowerCase().startsWith(value.toLowerCase()));

    if (!result.length) {
      return <div className={styles.nothingFound}>Nothing found</div>;
    }

    return (
      <ul className={styles.dropdownOptionsList}>
        {result.map((option, index) => (
          <div key={index} className={styles.dropdownOptionContainer}>
            <li className={clsx(styles.dropdownOption)} onMouseDown={handleSelect(option)}>
              {option}
            </li>
          </div>
        ))}
      </ul>
    );
  };

  const renderIcon = () => {
    if (!value) {
      return <SearchIcon className={styles.searchIcon} />;
    }

    return (
      <div
        className={styles.closeIcon}
        onClick={() => {
          onChange('');
          if (onClear) {
            onClear();
          }
        }}
      >
        <CloseIcon />
      </div>
    );
  };

  return (
    <div className={className}>
      <div className={clsx(styles.label, errorMessage && styles.errorLabel)}>{label}</div>
      <div className={styles.dropdownInput} ref={ref}>
        <Input
          placeholder={placeholder}
          onChange={(event) => onChange(event.target.value)}
          onClick={() => setOpen(true)}
          value={value || ''}
          onBlur={(event) => onBlur(event)}
          label={label}
          name={name}
          disabled={disabled}
          className={clsx(styles.searchInput, !!errorMessage && styles.error)}
          maxLength={maxLength}
        />
        {renderIcon()}
        {open && renderOptions()}
      </div>
      {!!errorMessage && <InputError errorMessage={errorMessage} />}
    </div>
  );
};

export default SearchInput;
