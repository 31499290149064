import React, { FC } from 'react';
import clsx from 'clsx';
import { PassedProductTableHeaderTitle } from 'enums/PassedProductTableHeaderTitle';
import { DeclinedProductTableHeaderTitle } from 'enums/DeclinedProductTableHeaderTitle';
import { ReactComponent as ArrowDown } from 'images/arrow-down.svg';

import { PortalType, settings } from 'settings';

import styles from './ProductHeader.module.scss';

interface ProductHeaderProps {
  currentSortingField: string;
  headerTitles: PassedProductTableHeaderTitle[] | DeclinedProductTableHeaderTitle[];
  portalType: PortalType;
  onClick: (title: PassedProductTableHeaderTitle | DeclinedProductTableHeaderTitle) => void;
  className?: string;
  ascending?: boolean;
}

const ProductHeader: FC<ProductHeaderProps> = ({
  className,
  onClick,
  currentSortingField,
  ascending,
  headerTitles,
  portalType,
}) => (
  <div className={clsx(styles[settings[portalType].productHeaderContainerClassName], className)}>
    {headerTitles.map((title) => (
      <div className={styles.titleContainer} key={title} onClick={() => onClick(title)}>
        <p className={clsx(styles.title, title === currentSortingField && styles.currentSortingTitle)}>{title}</p>
        <ArrowDown className={clsx(ascending && title === currentSortingField && styles.arrowRotated)} />
      </div>
    ))}
  </div>
);

export default ProductHeader;
