import { useLayoutEffect } from 'react';

export interface Theme {
  [name: string]: string;
}

const useTheme = (theme: Theme | null): void => {
  useLayoutEffect((): void => {
    if (!theme) return;

    Object.keys(theme).forEach((key) => {
      document.documentElement.style.setProperty(`--${key}`, theme[key]);
    });
  }, [theme]);
};

export default useTheme;
