import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductVariable } from 'enums/ProductVariable';
import { getPreQualificationData } from 'thunks';
import { PassedProgramsData, PrequalificationResult } from 'api/PreQualificationApi';
import { LockTerm } from 'enums/LockTerm';
import { StrategyId } from 'enums/StrategyId';
import { SessionStorageKeyName } from 'enums/SessionStorageKeyName';
import { getSessionStorageItem } from 'utils/getSessionStorageItem';

export interface DeclinedProgramsData {
  [ProductVariable.LoanProduct]: string;
  [ProductVariable.DeclineReasons]: string[];
}

export interface PreQualificationResultsFilters {
  loanProductsFilter: StrategyId[];
  lockTermFilter: string;
}

export interface PreQualificationResultsState {
  isLoading: boolean;
  passedPrograms: Array<PassedProgramsData> | null;
  declinedPrograms: Array<DeclinedProgramsData> | null;
  filters: PreQualificationResultsFilters;
  isFilterOpen: boolean;
  decisionId: string | undefined;
}

export interface SavedPreQualificationResultsState {
  passedPrograms: Array<PassedProgramsData> | null;
  declinedPrograms: Array<DeclinedProgramsData> | null;
  filters: PreQualificationResultsFilters;
  decisionId: string | undefined;
}

export const initialFiltersState: PreQualificationResultsFilters = {
  loanProductsFilter: [
    StrategyId.AssetQualification,
    StrategyId.BankStatement,
    StrategyId.DSCR,
    StrategyId.DscrElite,
    StrategyId.DSCRLowRatio,
    StrategyId.ForeignNational,
    StrategyId.FullDocumentation,
    StrategyId.MixedUse,
    StrategyId.DscrNoRatio,
    StrategyId.ProfitLossOnly,
  ],
  lockTermFilter: LockTerm.FifteenDayLock,
};

const prequalificationResultsInitialState: SavedPreQualificationResultsState = getSessionStorageItem(
  SessionStorageKeyName.PrequalificationResults,
);

const initialState: PreQualificationResultsState = {
  isLoading: false,
  passedPrograms: prequalificationResultsInitialState?.passedPrograms ?? null,
  declinedPrograms: prequalificationResultsInitialState?.declinedPrograms ?? null,
  filters: prequalificationResultsInitialState?.filters ?? initialFiltersState,
  isFilterOpen: false,
  decisionId: prequalificationResultsInitialState?.decisionId ?? undefined,
};

const preQualificationResultsReducer = createSlice({
  name: 'prequalificationResults',
  initialState,
  reducers: {
    setPreQualificationResultsFilters: (
      state: PreQualificationResultsState,
      { payload }: PayloadAction<PreQualificationResultsFilters>,
    ) => {
      state.filters = payload;
    },
    setFilterOpen: (state: PreQualificationResultsState, { payload }: PayloadAction<boolean>) => {
      state.isFilterOpen = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPreQualificationData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPreQualificationData.fulfilled, (state, { payload }: PayloadAction<PrequalificationResult>) => {
      state.isLoading = false;
      state.passedPrograms = payload.passedPrograms;
      state.declinedPrograms = payload.declinedPrograms;
      state.decisionId = payload.decisionId;
    });
    builder.addCase(getPreQualificationData.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setPreQualificationResultsFilters, setFilterOpen } = preQualificationResultsReducer.actions;

export default preQualificationResultsReducer.reducer;
