export const compareArrays = (
  firstArray: Array<string | number> | null,
  secondArray: Array<string | number> | null,
) => {
  if (firstArray === null || secondArray === null) {
    return true;
  }

  return (
    firstArray.length === secondArray.length &&
    firstArray.every((element, index) => {
      return element === secondArray[index];
    })
  );
};
