import { useEffect, useState } from 'react';

import useTheme, { Theme } from './useTheme';

const useThemeByPortalType = async (stylePath?: string) => {
  const [styles, setStyles] = useState<Theme | null>(null);
  const [loading, setLoading] = useState(true);

  const fetchStyles = async () => {
    const fetchedStyles = await fetch(`portalsStyles/${stylePath}.json`);
    const parsedStyles = await fetchedStyles.json();
    setStyles(parsedStyles);
    setLoading(false);
  };

  useEffect(() => {
    fetchStyles();
  }, [stylePath]);

  useTheme(styles);

  return [loading];
};

export default useThemeByPortalType;
