import { combineReducers } from '@reduxjs/toolkit';

import preQualificationFormReducer from './prequalificationForm';
import prequalificationResultsReducer from './prequalificationResults';
import prequalificationResultDetailsReducer from './prequalificationResultDetails';
import isStylesLoadedReducer from './isStylesLoaded';


const rootReducer = combineReducers({
  preQualificationForm: preQualificationFormReducer,
  prequalificationResults: prequalificationResultsReducer,
  prequalificationResultDetails: prequalificationResultDetailsReducer,
  isStylesLoaded: isStylesLoadedReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
