import React from 'react';
import { ProductDisclaimers } from 'api/PreQualificationApi';

import clsx from 'clsx';

import styles from './DisclaimerBox.module.scss';

interface DisclaimerBoxProps {
  disclaimers: ProductDisclaimers;
  className?: string;
}

const DisclaimerBox = ({ disclaimers, className }: DisclaimerBoxProps) => {
  return (
    <div className={clsx(styles.disclaimerBox, className)}>
      {disclaimers.disclaimer1 && <p>{disclaimers.disclaimer1}</p>}
      {disclaimers.disclaimer2 && (
        <p>
          <span className={styles.disclaimerTitle}>Prepayment Disclaimer: </span> {disclaimers.disclaimer2}
        </p>
      )}
      {disclaimers.disclaimer3 && <p className={styles.listTypeDisclaimer}>{disclaimers.disclaimer3}</p>}
    </div>
  );
};

export default DisclaimerBox;
