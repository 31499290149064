import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import AppRoutes from 'routes';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { env } from 'env';

import App from 'App';

import store, { history } from './store';
import { PortalType } from './settings';
import { EnvironmentType } from './enums/EnvironmentType';

import './index.scss';

const environment = process.env.REACT_APP_ENVIRONMENT;

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.1,
  enabled: environment === EnvironmentType.Production,
});

export interface PortalParamsType {
  portalType: PortalType;
}

export const PortalContext = createContext<PortalParamsType | null>(null);

const portalParams: PortalParamsType = {
  portalType: env.REACT_APP_WHITE_LABEL as PortalType,
};

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <PortalContext.Provider value={portalParams}>
        <App>
          <AppRoutes />
        </App>
      </PortalContext.Provider>
    </Router>
  </Provider>,
  document.getElementById('root'),
);
