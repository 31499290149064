import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import useSorting, { SortingType } from 'hooks/useSorting';
import { ProductVariable } from 'enums/ProductVariable';
import { PassedProgramsData, PricesToRates } from 'api/PreQualificationApi';
import { createPriceAndRatesSortingHandler } from 'utils/createPriceAndRatesSortingHandler';
import { filterPricesAndRates } from 'utils/filterPricesAndRates';
import { compareArrays } from 'utils/compareTwoArrays';
import { RootState } from 'handlers';
import { setPricesAndRatesFilter, setPricesAndRatesFilterOpen } from 'handlers/prequalificationResultDetails';
import BackLink from 'components/BackLink';
import DropdownVariable from 'components/Dropdown/DropdownVariable';
import Button from 'components/Button';
import Popup from 'components/Popup';
import ProductDetailsFilter from 'components/Filters/ProductDetailsFilter';
import { ButtonType } from 'components/Button/Button';
import { ReactComponent as GradientFilterIcon } from 'images/gradient-filter-icon.svg';
import { ReactComponent as GradientPrintIcon } from 'images/gradient-print-icon.svg';
import { useReactToPrint } from 'react-to-print';

import PrequalificationResultPDF from 'components/PrequalificationResultPDF';

import { ProductType } from 'enums/ProductType';

import clsx from 'clsx';

import Pricing from './Pricing';
import DisclaimerBox from './DisclaimerBox';

import styles from './PrequalificationResultDetails.module.scss';

export interface PricesToRatesWithApr extends PricesToRates {
  apr: number;
}

const SORTED_ITEMS_FIRST_ELEMENT_INDEX = 0;

interface PrequalificationResultDetailsProps {
  currentProgram: PassedProgramsData;
  productType: ProductType;
}

const PrequalificationResultDetails: FC<PrequalificationResultDetailsProps> = ({ currentProgram, productType }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { filter, isFilterOpen, filterAvailableValues } = useSelector(
    (state: RootState) => state.prequalificationResultDetails,
  )!;
  const { application } = useSelector((state: RootState) => state.preQualificationForm);

  const [selectedBpcRowId, setSelectedBpcRowId] = useState(currentProgram.bpcBestOption?.id);
  const [selectedLpcRowId, setSelectedLpcRowId] = useState(
    currentProgram.lpcBestOption ? currentProgram.lpcBestOption.id : null,
  );

  const containerRef = useRef(null);

  useEffect(() => {
    return () => {
      dispatch(setPricesAndRatesFilterOpen(false));
    };
  }, []);

  const handleBackLink = () => history.push('/results');

  const [lpcDataSortingType, setLpcDataSortingType] = useState<SortingType<string>>({
    field: 'Rate/Price',
    ascending: true,
  });

  const [bpcDataSortingType, setBpcDataSortingType] = useState<SortingType<string>>({
    field: 'Rate/Price',
    ascending: true,
  });

  const [lpcDataChangeSorting] = useSorting(
    lpcDataSortingType.field,
    lpcDataSortingType.ascending,
    (field, ascending) => {
      setLpcDataSortingType({
        field,
        ascending,
      });
    },
  );

  const [bpcDataChangeSorting] = useSorting(
    bpcDataSortingType.field,
    bpcDataSortingType.ascending,
    (field, ascending) => {
      setBpcDataSortingType({
        field,
        ascending,
      });
    },
  );

  const lpcItems: PricesToRates[] | null = useMemo(() => {
    return filter!.lpcRateRange
      ? [...currentProgram.lpcData]
          .sort(createPriceAndRatesSortingHandler(lpcDataSortingType.field, lpcDataSortingType.ascending))
          .filter((item) =>
            filterPricesAndRates(item, filter!.lpcRateRange!, filter!.lpcPriceRange!, filter!.bpcPointsRange!),
          )
      : null;
  }, [currentProgram.lpcData, lpcDataSortingType, filter]);

  const bpcItems: PricesToRatesWithApr[] = useMemo(() => {
    return [...currentProgram.bpcData]
      .sort(createPriceAndRatesSortingHandler(bpcDataSortingType.field, bpcDataSortingType.ascending))
      .filter((item) =>
        filterPricesAndRates(item, filter!.bpcRateRange, filter!.bpcPriceRange, filter!.bpcPointsRange!),
      )
      .map((item) => {
        const option = currentProgram.aprData.find((aprItem) => aprItem.bpcId === item.id);

        return {
          ...item,
          apr: option ? option.value : 0,
        };
      });
  }, [currentProgram.bpcData, bpcDataSortingType, filter]);

  const isFilterInInitialState =
    compareArrays(filter!.lpcRateRange, filterAvailableValues!.lpcRateRange) &&
    compareArrays(filter!.lpcPriceRange, filterAvailableValues!.lpcPriceRange) &&
    compareArrays(filter!.bpcRateRange, filterAvailableValues!.bpcRateRange) &&
    compareArrays(filter!.bpcPriceRange, filterAvailableValues!.bpcPriceRange) &&
    compareArrays(filter!.bpcPointsRange, filterAvailableValues!.bpcPointsRange);

  const handleReset = () => {
    dispatch(setPricesAndRatesFilter(filterAvailableValues!));
  };

  const showDisclaimerBox = Object.values(currentProgram.productDisclaimers).some((item) => !!item);

  const printToPDF = useReactToPrint({
    content: () => containerRef.current,
    documentTitle: 'Prequalification Result Details',
  });

  const currentBpcRow =
    bpcItems.find((item) => item.id === selectedBpcRowId) || bpcItems[SORTED_ITEMS_FIRST_ELEMENT_INDEX];

  const currentLpcRow = lpcItems
    ? lpcItems.find((item) => item.id === selectedLpcRowId) || lpcItems[SORTED_ITEMS_FIRST_ELEMENT_INDEX]
    : null;

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <BackLink title="Back to Results" onClick={handleBackLink} className={styles.backLink} />
        <div className={styles.headerTitle}>
          <h1 className={styles.header}>Product Details</h1>
          <Button
            type={ButtonType.Gradient}
            onClick={() => printToPDF()}
            active={!isFilterInInitialState}
            className={styles.printButton}
            icon={<GradientPrintIcon className={styles.printButtonIcon} />}
          >
            Print to PDF
          </Button>
        </div>
      </div>
      <div className={styles.termsBoxContainer}>
        <DropdownVariable title="Loan Product" value={currentProgram[ProductVariable.LoanProduct]} />
        <DropdownVariable title="Product Terms" value={currentProgram[ProductVariable.ProductTerms]} />
        <DropdownVariable title="Lock Term" value={currentProgram[ProductVariable.LockTerm]} />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.contentTitleContainer}>
          <h3 className={styles.contentTitle}>Pricing</h3>
          <div className={styles.buttonsContainer}>
            <Button
              type={ButtonType.Gradient}
              onClick={() => dispatch(setPricesAndRatesFilterOpen(true))}
              active={!isFilterInInitialState}
              className={styles.filterButton}
              icon={<GradientFilterIcon className={styles.gradientFilterIcon} />}
            >
              Filter
            </Button>
            {!isFilterInInitialState && (
              <Button type={ButtonType.GradientWithNoIcon} onClick={handleReset} className={styles.resetButton}>
                Reset
              </Button>
            )}
          </div>
        </div>
        <div className={clsx(selectedLpcRowId ? styles.pricingContainer : styles.singlePricingContainer)}>
          {selectedLpcRowId && (
            <div className={styles.content}>
              <p className={styles.contentSectionTitle}>Lender Paid Compensation (LPC)</p>
              <Pricing
                sortedItems={lpcItems!}
                onSort={() => lpcDataChangeSorting('Rate/Price')}
                sortingType={lpcDataSortingType}
                bestPriceOption={currentProgram.lpcBestOption}
                currentPriceOption={currentLpcRow!}
                lenderPaidCompensation={currentProgram.lenderPaidCompensation}
                priceAdjustmentsList={currentProgram.priceAdjustmentsList}
                currentProductTerm={currentProgram[ProductVariable.ProductTerms]}
                changeSelectedRow={setSelectedLpcRowId}
              />
            </div>
          )}
          <div className={styles.content}>
            {selectedLpcRowId && <p className={styles.contentSectionTitle}>Borrower Paid Compensation (BPC)</p>}
            <Pricing
              sortedItems={bpcItems}
              onSort={bpcDataChangeSorting}
              sortingType={bpcDataSortingType}
              bestPriceOption={currentProgram.bpcBestOption}
              currentPriceOption={currentBpcRow}
              priceAdjustmentsList={currentProgram.priceAdjustmentsList}
              currentProductTerm={currentProgram[ProductVariable.ProductTerms]}
              changeSelectedRow={(id: string) => setSelectedBpcRowId(id)}
              isRatePoints={!selectedLpcRowId}
            />
          </div>
        </div>
      </div>
      {showDisclaimerBox && <DisclaimerBox disclaimers={currentProgram.productDisclaimers} />}
      {isFilterOpen && (
        <Popup disableBodyScroll>
          <ProductDetailsFilter
            onPopupClose={() => dispatch(setPricesAndRatesFilterOpen(false))}
            currentProgram={currentProgram}
            filter={filter!}
            filterAvailableValues={filterAvailableValues!}
          />
        </Popup>
      )}
      <PrequalificationResultPDF
        productType={productType}
        currentProgram={currentProgram}
        containerReference={containerRef}
        lpcItems={lpcItems}
        bpcItems={bpcItems}
        values={application}
        currentBpcPriceOption={currentBpcRow}
        currentLpcPriceOption={currentLpcRow}
        changeSelectedBpcRow={(id: string) => setSelectedBpcRowId(id)}
        changeSelectedLpcRow={(id: string) => setSelectedLpcRowId(id)}
      />
    </div>
  );
};

export default PrequalificationResultDetails;
