import React, { FC } from 'react';
import Header from 'components/Header';
import ProductTypeSwitcher from 'components/ProductTypeSwitcher';

import { ProductType } from 'enums/ProductType';

import styles from './HomeLeftBar.module.scss';

const switcherOptions = [
  {
    title: ProductType.AlternativeDocumentation,
    subtitle: 'Programs utilizing alternative documentation such as bank statements or assets.',
  },
  {
    title: ProductType.FullDocumentation,
    subtitle: 'Programs utilizing traditional income documentation.',
  },
  {
    title: ProductType.DebtServiceCoverageRatio,
    subtitle: 'Programs utilizing the cash flow of the subject property.',
  },
];

interface HomeLeftBarProps {
  onChange: (value: ProductType) => void;
  currentProduct: string;
}

const HomeLeftBar: FC<HomeLeftBarProps> = ({ onChange, currentProduct }) => (
  <div className={styles.container}>
    <div className={styles.content}>
      <div className={styles.topBar}>
        <Header disposedInBlock />
        <div className={styles.contentContainer}>
          <h4 className={styles.switcherTitle}>Product Type</h4>
          <div>
            {switcherOptions.map((option) => (
              <ProductTypeSwitcher
                type="radio"
                name="Product Type"
                title={option.title}
                subtitle={option.subtitle}
                onChange={() => onChange(option.title)}
                checked={currentProduct === option.title}
                key={option.title}
                className={styles.radioButton}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HomeLeftBar;
