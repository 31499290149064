import * as Yup from 'yup';
import { PrequalificationInputName } from 'enums/PrequalificationInputName';
import { PrequalificationInputLabel } from 'enums/PrequalificationInputLabel';
import { ProductType } from 'enums/ProductType';
import { getMessageForInvalidFields, getMessageForRequiredFields } from 'utils/errors';
import { countyByStateList } from 'utils/counyByStateList';
import { PortalType, settings } from 'settings';

import { countryStates } from './stateSearchOptions';

export const validationSchema = (productType: ProductType, portalType: PortalType) =>
  Yup.object().shape({
    [PrequalificationInputName.LoanPurpose]: Yup.string().required(
      getMessageForRequiredFields(PrequalificationInputLabel.LoanPurpose),
    ),
    [PrequalificationInputName.PurchasePriceOrEstimatedValue]: Yup.string().required(
      getMessageForRequiredFields(PrequalificationInputLabel.PurchasePriceOrEstimatedValue),
    ),
    [PrequalificationInputName.LoanToValue]: Yup.string().required(
      getMessageForRequiredFields(PrequalificationInputLabel.LoanToValue),
    ),
    [PrequalificationInputName.LoanAmount]: Yup.string().required(
      getMessageForRequiredFields(PrequalificationInputLabel.LoanAmount),
    ),
    [PrequalificationInputName.Reserves]: Yup.string().required(
      getMessageForRequiredFields(PrequalificationInputLabel.Reserves),
    ),
    [PrequalificationInputName.SelfEmployed]: Yup.string().required(
      getMessageForRequiredFields(PrequalificationInputLabel.SelfEmployed),
    ),

    [PrequalificationInputName.DebtToIncome]: Yup.string().when('productType', {
      is: () => productType === ProductType.AlternativeDocumentation || productType === ProductType.FullDocumentation,
      then: Yup.string().required(getMessageForRequiredFields(PrequalificationInputLabel.DebtToIncome)),
    }),
    [PrequalificationInputName.IncomeDocumentType]: Yup.string().when('productType', {
      is: () => productType === ProductType.AlternativeDocumentation || productType === ProductType.FullDocumentation,
      then: Yup.string().required(getMessageForRequiredFields(PrequalificationInputLabel.IncomeDocumentType)),
    }),
    [PrequalificationInputName.InterestOnly]: Yup.string().required(
      getMessageForRequiredFields(PrequalificationInputLabel.InterestOnly),
    ),
    [PrequalificationInputName.Citizenship]: Yup.string().required(
      getMessageForRequiredFields(PrequalificationInputLabel.Citizenship),
    ),
    [PrequalificationInputName.PrepaymentPenalty]: Yup.string().required(
      getMessageForRequiredFields(PrequalificationInputLabel.PrepaymentPenalty),
    ),
    [PrequalificationInputName.EscrowWaiver]: Yup.string().required(
      getMessageForRequiredFields(PrequalificationInputLabel.EscrowWaiver),
    ),
    [PrequalificationInputName.CreditScore]: Yup.string().required(
      getMessageForRequiredFields(PrequalificationInputLabel.CreditScore),
    ),
    [PrequalificationInputName.MortgagePaymentHistory]: Yup.string().required(
      getMessageForRequiredFields(PrequalificationInputLabel.MortgagePaymentHistory),
    ),
    [PrequalificationInputName.CreditEvents]: Yup.string().required(
      getMessageForRequiredFields(PrequalificationInputLabel.CreditEvents),
    ),
    [PrequalificationInputName.PropertyType]: Yup.string().required(
      getMessageForRequiredFields(PrequalificationInputLabel.PropertyType),
    ),
    [PrequalificationInputName.Occupancy]: Yup.string().required(
      getMessageForRequiredFields(PrequalificationInputLabel.Occupancy),
    ),
    [PrequalificationInputName.Vesting]: Yup.string().required(
      getMessageForRequiredFields(PrequalificationInputLabel.Vesting),
    ),
    [PrequalificationInputName.State]: Yup.string()
      .required(getMessageForRequiredFields(PrequalificationInputLabel.State))
      .test('isStateValid', getMessageForInvalidFields(PrequalificationInputLabel.State), (value) => {
        if (!value) {
          return true;
        }

        return countryStates.includes(value as string);
      }),
    [PrequalificationInputName.County]: Yup.string().when(PrequalificationInputName.State, {
      is: (state: string) => !!state && countryStates.includes(state),
      then: Yup.string()
        .required(getMessageForRequiredFields(PrequalificationInputLabel.County))
        .test('isCountyValid', getMessageForInvalidFields(PrequalificationInputLabel.County), (county, context) => {
          const { parent } = context;
          const validCounties = countyByStateList[parent[PrequalificationInputName.State]] || [];
          return validCounties.includes(county as string);
        }),
      otherwise: Yup.string().notRequired(),
    }),
    [PrequalificationInputName.LenderPaidCompensation]: Yup.string().when('productType', {
      is: () => settings[portalType].validateLenderPaidCompensation,
      then: Yup.string().required(getMessageForRequiredFields(PrequalificationInputLabel.LenderPaidCompensation)),
    }),
  });
