export enum FilerRangeInputName {
  LpcRateMinValue = 'LpcRateMinValue',
  LpcRateMaxValue = 'LpcRateMaxValue',
  LpcPriceMinValue = 'LpcPriceMinValue',
  LpcPriceMaxValue = 'LpcPriceMaxValue',

  BpcRateMinValue = 'BpcRateMinValue',
  BpcRateMaxValue = 'BpcRateMaxValue',
  BpcPriceMinValue = 'BpcPriceMinValue',
  BpcPriceMaxValue = 'BpcPriceMaxValue',

  BpcPointsMinValue = 'BpcPointsMinValue',
  BpcPointsMaxValue = 'BpcPointsMaxValue',
}
