import React from 'react';
import clsx from 'clsx';

import styles from 'components/CheckableSelect/CheckableSelect.module.scss';

interface RadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
}

const RadioButton = ({ label, name, onChange, className, ...rest }: RadioButtonProps) => (
  <div className={className}>
    <label className={styles.container}>
      <span className={styles.checkboxLabel}>{label}</span>
      <input type="radio" name={name} {...rest} onChange={onChange} />
      <span className={clsx(styles.checkmark, styles.radioButtonCheckmark)} />
    </label>
  </div>
);

export default RadioButton;
