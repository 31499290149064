import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from 'handlers';
import Collapsible from 'react-collapsible';
import { ReactComponent as SelectArrow } from 'images/dropdown-arrow.svg';
import { ReactComponent as EditPencilIcon } from 'images/edit-pencil-icon.svg';
import { ReactComponent as GradientEditPencilIcon } from 'images/gradient-edit-pencil-icon.svg';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';

import { PortalContext, PortalParamsType } from 'index';
import { settings } from 'settings';

import { Mapping, variablesMapping } from './variablesMapping';
import DropdownVariable from './DropdownVariable';

import styles from './Dropdown.module.scss';

const LENDER_INFORMATION_TITLE = 'Lender Information';

const Dropdown = () => {
  const { portalType } = useContext(PortalContext) as PortalParamsType;
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const { application, coercedApplication } = useSelector((state: RootState) => state.preQualificationForm);

  const handleDropdownToggle = () => setOpen(!open);

  const handleEditData = () => {
    history.push('/');
  };

  const isLenderInformationNeeded = settings[portalType].showLenderInformationOnPrequalificationPage;

  const renderMappingVariables = (mappingObject: Mapping) => {
    if (!isLenderInformationNeeded && mappingObject.title === LENDER_INFORMATION_TITLE) return;

    return (
      <div key={mappingObject.title} className={styles.contentSection}>
        <p className={styles.sectionTitle}>{mappingObject.title}</p>
        <div className={styles.variablesContainer}>
          {mappingObject.variables.map(
            (variable) =>
              application[variable.name] &&
              coercedApplication![variable.systemName] != null && (
                <DropdownVariable
                  className={styles.dropdownVariable}
                  title={variable.label}
                  value={application[variable.name]}
                  key={variable.name}
                />
              ),
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Collapsible
        transitionTime={200}
        trigger={
          <div className={styles.titleContainer} onClick={handleDropdownToggle}>
            <div className={styles.tileLeftBar}>
              <h3 className={styles.title}>Data Provided</h3>
              <Button
                type={ButtonType.Gradient}
                className={styles.desktopEditDataButton}
                onClick={handleEditData}
                icon={<GradientEditPencilIcon className={styles.desktopEditDataIcon} />}
              >
                {' '}
                Edit Data
              </Button>
            </div>
            <SelectArrow className={clsx(styles.selectArrow, open && styles.selectArrowActive)} />
          </div>
        }
      >
        <div className={clsx(styles.content)}>
          <div className={styles.sectionsContainer}>
            {variablesMapping.map((mappingObject) => renderMappingVariables(mappingObject))}
          </div>
          <Button
            type={ButtonType.Secondary}
            className={styles.mobilEditDataButton}
            onClick={handleEditData}
            icon={<EditPencilIcon className={styles.mobilEditDataIcon} />}
          >
            Edit Data
          </Button>
        </div>
      </Collapsible>
    </div>
  );
};

export default Dropdown;
