export interface SortingType<FieldType> {
  field: FieldType;
  ascending: boolean;
}

const useSorting = <FieldType>(
  currentField: FieldType,
  ascending: boolean,
  onSort: (FieldType: FieldType, ascending: boolean) => void,
): [(field: FieldType) => void, (field: FieldType) => boolean | undefined] => {
  const changeSorting = (sortableField: FieldType) => {
    onSort(sortableField, currentField === sortableField ? !ascending : true);
  };

  const getSortingType = (field: FieldType) => (currentField === field ? ascending : undefined);

  return [changeSorting, getSortingType];
};

export default useSorting;
