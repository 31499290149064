import React from 'react';
import clsx from 'clsx';

import styles from './ProductTypeSwitcher.module.scss';

interface RadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
  title: string;
  subtitle: string;
  name: string;
}

const ProductTypeSwitcher = ({ name, onChange, className, title, subtitle, checked, ...rest }: RadioButtonProps) => (
  <div className={className}>
    <label className={clsx(styles.container, checked && styles.checkedContainer)}>
      <input type="radio" name={name} {...rest} onChange={onChange} className={styles.input} />
      <div className={clsx(styles.checkmark, checked && styles.checkedCheckmark)} />
      <div className={styles.labelContainer}>
        <p className={clsx(styles.title, checked && styles.checkedTitle)}>{title}</p>
        <p className={clsx(styles.subtitle, checked && styles.checkedSubtitle)}>{subtitle}</p>
      </div>
    </label>
  </div>
);

export default ProductTypeSwitcher;
