import React, { FC } from 'react';
import { createTheme, Slider, ThemeProvider } from '@mui/material';

import RangeSliderBackground from './range-slider-button.svg';

import styles from './RangeSlider.module.scss';

const theme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        track: {
          height: 2,
          backgroundColor: '#F0F3F8',
          border: 'none',
        },
        thumb: {
          width: 32,
          height: 32,
          cursor: 'pointer',
          borderRadius: 8,
          backgroundImage: `url(${RangeSliderBackground})`,
          touchAction: 'pan-x',
          ':hover': {
            boxShadow: 'none',
          },
        },
        rail: {
          height: 2,
          backgroundColor: '#E6EBF4',
        },
      },
    },
  },
});

interface RangeSliderProps {
  rangeValue: number[];
  onChange: (event: Event, value: number | number[], activeThumb: number) => void;
  minValue: number;
  maxValue: number;
  step: number;
  name: string;
}

const RangeSlider: FC<RangeSliderProps> = ({ rangeValue, onChange, minValue, maxValue, step, name }) => {
  return (
    <div className={styles.container}>
      <ThemeProvider theme={theme}>
        <Slider
          value={rangeValue}
          onChange={onChange}
          min={minValue}
          max={maxValue}
          step={step}
          name={name}
          disableSwap
        />
      </ThemeProvider>
    </div>
  );
};

export default RangeSlider;
