import React, { FC, useState } from 'react';
import { PortalType, settings } from 'settings';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'handlers';
import {
  initialFiltersState,
  setFilterOpen,
  setPreQualificationResultsFilters,
} from 'handlers/prequalificationResults';
import { compareArrays } from 'utils/compareTwoArrays';
import { LockTerm } from 'enums/LockTerm';
import { StrategyId } from 'enums/StrategyId';
import { PreQualificationData } from 'api/PreQualificationApi';
import { getPreQualificationData } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import Checkbox from 'components/CheckableSelect/Checkbox';
import RadioButton from 'components/CheckableSelect/RadioButton';
import Separator from 'components/Separator';
import PopupHeader from 'components/Popup/PopupHeader';
import FilterButtons from 'components/Filters/FilterButtons';

import { env } from 'env';

import styles from './ProductsFilter.module.scss';

const lockTerms = [LockTerm.FifteenDayLock, LockTerm.ThirtyDayLock];

interface ProductsFilterProps {
  portalType: PortalType;
  onPopupClose: () => void;
}

const ProductsFilter: FC<ProductsFilterProps> = ({ portalType, onPopupClose }) => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const { filters, isLoading } = useSelector((state: RootState) => state.prequalificationResults);
  const { coercedApplication } = useSelector((state: RootState) => state.preQualificationForm);

  const [selectedLoanProducts, setSelectedLoanProducts] = useState(filters.loanProductsFilter);
  const [selectedLoanTerm, setSelectedLoanTerm] = useState(filters.lockTermFilter);

  const [isResetLoading, setIsResetLoading] = useState(false);
  const [isApplyLoading, setIsApplyLoading] = useState(false);

  const onLoanProductIdChange = (loanProductId: StrategyId) => {
    const newSelectedLoanProduct = selectedLoanProducts.includes(loanProductId)
      ? selectedLoanProducts.filter((product) => product !== loanProductId)
      : [...selectedLoanProducts, loanProductId];

    setSelectedLoanProducts(newSelectedLoanProduct);
  };

  const isFiltersChanged =
    compareArrays(selectedLoanProducts, filters.loanProductsFilter) && selectedLoanTerm === filters.lockTermFilter;

  const isResetButtonDisabled =
    (compareArrays(filters.loanProductsFilter, initialFiltersState.loanProductsFilter) &&
      filters.lockTermFilter === initialFiltersState.lockTermFilter) ||
    (compareArrays(selectedLoanProducts, initialFiltersState.loanProductsFilter) &&
      selectedLoanTerm === initialFiltersState.lockTermFilter);

  const handleApply = async () => {
    const dataForGettingPrequalificationResult: PreQualificationData = {
      application: {
        ...coercedApplication!,
      },
      loanProductsFilter: selectedLoanProducts,
      lockTermFilter: selectedLoanTerm,
      portalType: env.REACT_APP_WHITE_LABEL as PortalType,
    };

    setIsApplyLoading(true);

    await dispatchWithUnwrap(getPreQualificationData(dataForGettingPrequalificationResult));

    setIsApplyLoading(false);

    dispatch(setFilterOpen(false));
    dispatch(
      setPreQualificationResultsFilters({
        loanProductsFilter: selectedLoanProducts,
        lockTermFilter: selectedLoanTerm,
      }),
    );
  };

  const handleReset = async () => {
    const dataForGettingPrequalificationResult: PreQualificationData = {
      application: {
        ...coercedApplication!,
      },
      loanProductsFilter: initialFiltersState.loanProductsFilter,
      lockTermFilter: initialFiltersState.lockTermFilter,
      portalType: env.REACT_APP_WHITE_LABEL as PortalType,
    };

    setIsResetLoading(true);

    await dispatchWithUnwrap(getPreQualificationData(dataForGettingPrequalificationResult));

    setIsResetLoading(false);

    dispatch(setFilterOpen(false));
    dispatch(
      setPreQualificationResultsFilters({
        loanProductsFilter: initialFiltersState.loanProductsFilter,
        lockTermFilter: initialFiltersState.lockTermFilter,
      }),
    );
  };

  const relevantStrategies = settings[portalType].productsFilterRelevantStrategies;

  return (
    <div className={styles.container}>
      <PopupHeader onPopupClose={onPopupClose} title="Filters" />
      <div className={styles.sectionContainer}>
        <h4 className={styles.sectionTitle}>Loan Products</h4>
        <div className={styles.checkboxesContainer}>
          {relevantStrategies.map((strategy) => (
            <Checkbox
              label={strategy.name}
              name={strategy.value}
              className={styles.checkbox}
              key={strategy.value}
              onChange={() => onLoanProductIdChange(strategy.value)}
              checked={selectedLoanProducts.includes(strategy.value)}
            />
          ))}
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <h4 className={styles.sectionTitle}>Lock Term</h4>
        <div>
          {lockTerms.map((lockTermName) => (
            <RadioButton
              label={`${lockTermName} Day Lock`}
              name="Lock Term"
              className={styles.radioButton}
              key={lockTermName}
              onChange={() => setSelectedLoanTerm(lockTermName)}
              checked={lockTermName === selectedLoanTerm}
            />
          ))}
        </div>
      </div>
      <Separator className={styles.separator} />
      <FilterButtons
        resetButtonDisabled={isResetButtonDisabled || (isApplyLoading && isLoading)}
        applyButtonDisabled={isFiltersChanged || (isResetLoading && isLoading)}
        handleResetFilters={handleReset}
        handleApply={handleApply}
        isApplyLoading={isApplyLoading && isLoading}
        isResetLoading={isResetLoading && isLoading}
      />
    </div>
  );
};

export default ProductsFilter;
