import React, { FC } from 'react';
import clsx from 'clsx';
import ReactLoading from 'react-loading';

import styles from './Loader.module.scss';

const LOADER_TYPE = 'bubbles';
const LOADER_SIZE = 80;

interface LoaderProps {
  isButtonLoader?: boolean;
  loaderColor?: string;
}

const Loader: FC<LoaderProps> = ({ isButtonLoader, loaderColor }) => {
  return (
    <ReactLoading
      type={LOADER_TYPE}
      color={loaderColor || '#ffffff'}
      width={LOADER_SIZE}
      height={LOADER_SIZE}
      className={clsx(isButtonLoader && styles.loader)}
    />
  );
};

export default Loader;
