import React, { FC } from 'react';
import useAutoScroll from 'hooks/useAutoScroll';
import useThemeByPortalType from 'hooks/useThemeByPortalType';
import { env } from 'env';
import { useDispatch, useSelector } from 'react-redux';
import { setIsStylesLoaded } from 'handlers/isStylesLoaded';
import { RootState } from 'handlers';

const App: FC = ({ children }) => {
  const dispatch = useDispatch();

  const { isStylesLoaded } = useSelector(
    (state: RootState) => state,
  ); 

  useAutoScroll();

  useThemeByPortalType(env.REACT_APP_PORTAL_NAME)
    .then(([loading]) => dispatch(setIsStylesLoaded(!loading)));

  return <>{isStylesLoaded && children}</>;
};

export default App;
