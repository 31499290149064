import React from 'react';
import clsx from 'clsx';

import styles from './InputError.module.scss';

interface InputErrorProps {
  errorMessage: string;
  className?: string;
}

const InputError = ({ errorMessage, className }: InputErrorProps) => (
  <div className={clsx(styles.inputErrorContainer, className)}>
    <p className={styles.inputError}>{errorMessage}</p>
  </div>
);

export default InputError;
